// utils/checkAuth.js
import { useNavigate } from "react-router-dom";
import { url } from "./utils";

export const useCheckAuth = () => {
  const navigate = useNavigate();

  const checkAuth = async () => {
    const UserToken = localStorage.getItem("token");
    const timein = localStorage.getItem("authTimein");
  
    const currentTime = new Date();
  
    if (UserToken) {
      const timeElapsed = currentTime - new Date(timein);
      const minutesElapsed = timeElapsed / (1000 * 60);
  
      // console.log({ currentTime });
      // console.log({minutesElapsed}, {timeElapsed});
  
      if (minutesElapsed > 120) { // Two hours in minutes
  
        // console.log("time > 2");
        
        try {
          const myHeaders = new Headers();
          myHeaders.append("Authorization", `Bearer ${UserToken}`);
  
          const requestOptions = {
            method: "POST",
            headers: myHeaders,
            redirect: "follow",
          };
  
          const response = await fetch(`${url}/api/sms/web/verify`, requestOptions);
          const result = await response.json();
  
          if (result.status === "bad") {
            // console.log("Bad result");
            navigate("/SignIn");
            return result.status;
          } else {
            // console.log("Verified successfully");
            return "success";
          }
        } catch (error) {
          // console.log("Error:", error);
          navigate("/SignIn");
          return error;
        }
      }
    } else {
      // console.log("Navigate");
      navigate("/SignIn");
      return "noToken";
    }
  };
  
  return checkAuth;
};