import React from 'react'
import { Link } from 'react-router-dom';
import * as Icon from 'react-feather';
import "./styles.scss";
import { DotLoader } from 'react-spinners';

const PrimaryButton = ({primary, variant, padding, icon, text, color, linkTo, handleClick, loading, ...otherProps}) => {
    const IconComponent = Icon[icon]; // Dynamically access the icon component

    return (
        <div className='primary-btn'>
            {linkTo ? (
                <>
                {!variant?(
                 <Link
                 className="link"
                 to={linkTo}
                 target='blank'
                 style={{ backgroundColor: color, padding: padding }}

             >
                 <span>{text}</span>
                 {icon && <IconComponent />}
             </Link>   
                ):(
                    <Link
                    className="link variant"
                    to={linkTo}
                    target='blank'
                    style={{ backgroundColor: color, padding: padding }}

                >
                    <span>{text}</span>
                    {icon && <IconComponent />}
                </Link>
                )}
                </>
            ) : (
                    !variant? (
                        <button
                        onClick={handleClick}
                        {...otherProps}
                        disabled={loading}
                        style={{ backgroundColor: color, padding: padding}}>
                            
                        <p
                            className="link"
                        >
                            {loading? 
                            <DotLoader color='#fff' size={10}/>
                            :
                            <span>{text}</span>

                        }
                            {icon && <IconComponent color={variant? '': 'white'} size={18}/>}
                        </p>
                    </button> 
                    ):(
                        <button
                        onClick={handleClick}
                        {...otherProps}
                        disabled={loading}
                        className='btn-style variant'
                        style={{ stroke: color, padding: padding}}>
                            
                        <p
                            className="link"
                        >
                            {loading? 
                            <DotLoader color='#fff' size={10}/>
                            :
                            <span>{text}</span>

                        }
                            {icon && <IconComponent />}
                        </p>
                    </button>
                    )
                )}

        </div>
    )
}

export default PrimaryButton