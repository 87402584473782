import React from 'react'
// import Header from '../Components/Auth/Header'
// import Footer from '../Components/Auth/Footer'

const NoNavLayout = props => {
  return (
    <div>
        {/* <Header {...props}/> */}

        <div>
            {props.children}
        </div>
        
        {/* <Footer {...props}/> */}
    </div>
  )
}

export default NoNavLayout