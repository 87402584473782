import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { saveAs } from 'file-saver';
import PrimaryButton from '../../../Components/PrimaryButton';
import StyledInput from '../../../Components/StyledInput';
import Select from "react-select";
import * as XLSX from 'xlsx';
import { formatDate, url } from '../../../utils/utils';

const SMSReport = () => {
    const navigate = useNavigate()
    const [queryLimit, setqueryLimit] = useState(25);
    const [queryPages, setqueryPages] = useState(1);
    const [queryPagesCount, setqueryPagesCount] = useState(0);
    const [reportData, setReportData] = useState([]);
    const [users, setUsers] = useState([]);
    const [actionBtn, setActionBtn] = useState(false);
    const [actionBtnId, setActionBtnId] = useState(null)
    const [modal, setModal] = useState(false)
    const [route, setRoute] = useState('MKT')
    const [loading, setLoading] = useState(false)
    const UserToken = localStorage.getItem("token");
    const [userData, setUserData] = useState({})


    const downloadCsv = () => {
        // Get the table element
        const table = document.getElementById('transact-history');

        // Create an array to store rows
        const rows = [];

        // Iterate over each row in the table
        for (let i = 0; i < table.rows.length; i++) {
            const row = [];
            // Iterate over each cell in the row
            for (let j = 0; j < table.rows[i].cells.length; j++) {
                row.push(table.rows[i].cells[j].innerText);
            }
            // Join the row elements with a comma to create a CSV row
            rows.push(row.join(','));
        }

        // Join the rows with a newline character to create the CSV content
        const csvContent = rows.join('\n');

        // Create a Blob containing the CSV content
        const blob = new Blob([csvContent], { type: 'text/csv' });

        // Create a link element
        const link = document.createElement('a');

        // Set the link's attributes
        link.href = window.URL.createObjectURL(blob);
        link.download = 'smarthive_transcation_history.csv';

        // Append the link to the document and trigger the click event
        document.body.appendChild(link);
        link.click();

        // Remove the link from the document
        document.body.removeChild(link);
    };

    const downloadExcel = () => {
        // Get the table element
        const table = document.getElementById('transact-history');

        // Create a worksheet
        const ws = XLSX.utils.table_to_sheet(table);

        // Create a workbook
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

        // Create a Blob containing the Excel file
        const blob = XLSX.write(wb, { bookType: 'xlsx', type: 'blob' });

        // Save the Blob as a file
        saveAs(blob, 'smarthive_transcation_history.xlsx');
    };

    useEffect(() => {
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${UserToken}`);

        const requestOptions = {
            method: "GET",
            headers: myHeaders,
            redirect: "follow"
        };

        fetch(`${url}/api/admin/get-daily-stat`, requestOptions)
            .then(response => response.json())
            .then(result => {

                if (result.status === 'bad') {
                    navigate("/SignIn");
                    return;
                  }
                  
                const data = result.data;

                const tableData = [];

                Object.keys(data).forEach(date => {
                    const operators = data[date];
                    Object.keys(operators).forEach(operator => {
                        const statuses = operators[operator];
                        const row = {
                            date: date,
                            operator: operator,
                            delivered: 0,
                            undeliv: 0,
                            rejected: 0,
                            expired: 0,
                            pending: 0,
                            others: 0
                        };

                        statuses.forEach(status => {
                            switch (status.status) {
                                case 'DELIVRD':
                                    row.delivered += status.totalSMS;
                                    break;
                                case 'UNDELIV':
                                    row.undeliv += status.totalSMS;
                                    break;
                                case 'REJECTD':
                                    row.rejected += status.totalSMS;
                                    break;
                                case 'EXPIRED':
                                    row.expired += status.totalSMS;
                                    break;
                                case 'PROCESSED':
                                    row.pending += status.totalSMS;
                                    break;
                                case 'OTHERS':
                                    row.others += status.totalSMS;
                                    break;
                                default:
                                    row.others += status.totalSMS;
                                    break;
                            }
                        });

                        tableData.push(row);
                    });
                });

                setReportData(tableData);
            })
            .catch(error => console.error(error));
    }, [UserToken]);

    return (
        <div className='topup-main-container'>
            <div className='head-line flex'>
                <p className='head-1'>Manage Sender IDs</p>
                <p className='head-2'>Dashboard</p>
                <p className='head-2'>{'>'}</p>

                <p className='head-2'>Admin</p>
                <p className='head-2'>{'>'}</p>

                <p className='head-2'>SMS Report</p>

            </div>

            <div className='topup-content-container history'>

                <div className='flex justify-between'>
                    <div className='flex'>
                        <p>Download</p>

                        <p className='each_option' onClick={() => downloadCsv()}>CSV</p>
                        <p className='each_option' onClick={() => downloadExcel()}>Excel</p>
                        {/* <p className='each_option' onClick={()=>  window.print()}>Print</p> */}

                    </div>


                </div>
                <div className='comp-container-sid'>
                    <div className='comp-container-inner'>
                        <div className='table-container'>
                            <table id="transact-history">
                                <thead>
                                    <tr>
                                        <th>Date</th>
                                        <th>Operator</th>
                                        <th>Total</th>
                                        <th>Delivered</th>
                                        <th>Undeliv</th>
                                        <th>Rejected</th>
                                        <th>Expired</th>
                                        <th>Submitted</th>
                                        <th>Others</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {reportData && reportData.length > 0 && reportData.slice().reverse().map((item, index) => (
                                        <tr key={index}>
                                            {/* <td class="selector">
                                                <input type="checkbox" id="selectRow1" class="select-row" />
                                            </td> */}
                                            {/* <td>
                                                {index + 1}
                                            </td> */}
                                            <td>{item.date}</td>
                                            <td>{item.operator}</td>
                                            <td>{item.delivered + item.undeliv + item.rejected + item.expired + item.pending + item.others}</td>
                                            <td>{item.delivered}</td>
                                            <td>{item.undeliv}</td>
                                            <td>{item.rejected}</td>
                                            <td>{item.expired}</td>
                                            <td>{item.pending}</td>
                                            <td>{item.others}</td>

                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>

                        <div className='flex bottom-qry-container'>

                            <div className='flex'>
                                <div className='selector-container'>
                                    <select id="yourSelector" value={queryLimit}>
                                        {/* <option value="">Select...</option> */}
                                        <option value="25">25</option>
                                        <option value="50">50</option>
                                        <option value="100">100</option>
                                        <option value="200">200</option>
                                        {/* Add more options as needed */}
                                    </select>

                                </div>
                                {/* <span> {queryPages} - {queryPagesCount} of {queryPagesCount}</span> */}

                            </div>

                            <div className='page-nav-container'>
                                <span>{'<'}</span>
                                <span className='count'>{queryPages}</span>
                                <span>{'>'}</span>

                            </div>

                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default SMSReport