import React, { useEffect } from "react";
import "./styles.scss";

const Popup = ({title,  message, onClose, show }) => {
  useEffect(() => {
    if (show) {
      const timeout = setTimeout(() => {
        onClose();
      }, 2000);

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [onClose, show]);

  return (
    <div className={true ? 'show-popup active' : 'show-popup'}>
          
          <div className='show-popup-inner'>
              <p>{title}</p>
              <p>{message}</p>
          </div>

      </div>
  );
};

export default Popup;
