import React from 'react'
import { Line, Bar } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { colors } from "../../constants/colors";

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    Title,
    Tooltip,
    Legend
);

const Chart = ({ bar, line, datas, label, labelTitle, chartTitle, color, monthly }) => {
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];

    const datasets = datas.map((dataSet, index) => ({
        label: `${dataSet.title}`, // You can customize the label
        data: dataSet.data,
        fill: false,
        borderColor: !color ? '#000' : color,
        tension: 0.1,
        pointBackgroundColor: 'red',
        ...(bar ? {
            backgroundColor:
                dataSet.title === 'Delivered' ? 'green' : dataSet.title === 'Rejected' ? 'red' : dataSet.title === 'Undelivered' ? 'yellow' : 'grey',
            borderWidth: 0
        } : {}),
    }));

    const data = {
        labels: monthly ? months : label,
        datasets: datasets
    };

    const lineOptions = {
        responsive: true,
        interaction: {
            mode: 'index',
            intersect: true,
        },
        stacked: false,
        plugins: {
            title: {
                display: chartTitle,
                text: chartTitle,
                color: '#000',
                font: {
                    size: 14
                }
            },
        },
        scales: {
            y: {
                type: 'linear',
                display: true,
                position: 'left',
            },
            y1: {
                type: 'linear',
                display: false,
                position: 'right',
                grid: {
                    drawOnChartArea: false,
                },
            },
        },
    };

    const barOptions = {
        responsive: true,
        scales: {
            x: {
                type: 'category',
            },
            y: {
                beginAtZero: true,
            },
        },
        plugins: {
            title: {
                display: chartTitle,
                text: chartTitle,
                color: '#000',
            },
        },
    };


    return (
        <>
            {bar ? <Bar data={data} options={barOptions} />
                :
                <Line data={data} options={lineOptions} />}
        </>

    )
}

export default Chart