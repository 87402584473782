import React, { useState, useEffect } from "react";
import { url } from "../../../utils/utils";
import "./style.scss";
import { useParams } from "react-router-dom";
import arrow from "../../../assets/arrow-down.svg";
import Spinner from "../../../Components/Spinner";

export default function SENT() {
  const { id } = useParams();
  const [value, setValue] = useState("");
  const [gottenResults, setGottenResults] = useState([]);
  const [response, setResponse] = useState([]);
  const [userData, setUserData] = useState({});
  const [route, setRoute] = useState("")
  const [type, setType] = useState("")

  const handleInputChange = (e) => {
    const newValue = Math.max(0, parseInt(e.target.value, 10) || 0);
    setValue(newValue);
  };

  useEffect(() => {
    const usrDT = localStorage.getItem("userData");
    const retrievedData = JSON.parse(usrDT);
    setUserData(retrievedData);


    const storedResults = JSON.parse(localStorage.getItem(id));
    setGottenResults(storedResults);

    console.log(storedResults.ids)
    const tk = localStorage.getItem("token");

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${tk}`);

    var raw = JSON.stringify({
      msgId: storedResults.ids,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${url}/api/sms/web/sent?uid=${retrievedData.uid}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setResponse(result);
      })
      .catch((error) => console.log("error", error));
  }, [id, userData.uid]);

  // console.log(response.data);
  const responseArray = response.data;
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const formattedDate = `${("0" + date.getDate()).slice(-2)}-${(
      "0" +
      (date.getMonth() + 1)
    ).slice(-2)}-${date.getFullYear()} ${("0" + date.getHours()).slice(-2)}:${(
      "0" + date.getMinutes()
    ).slice(-2)}:${("0" + date.getSeconds()).slice(-2)}`;
    return formattedDate;
  };

  return (
    <section className="sms_sent">
      <main className="sms-sent-area">
        <div className="form-header">
          <h2>Send Bulk SMS</h2>
          <div>
            <p>Dashboard</p>
            <img src={arrow} alt="" />
            <p>SMS</p>
            <img src={arrow} alt="" />
            <span>Send SMS</span>
            <img src={arrow} alt="" />
            <span>Send SMS transaction page</span>
          </div>
        </div>
        <div className="bg-white rounded-lg p-8">
          {!responseArray?.length &&
            <div className="fixed w-full h-full top-0 left-0 flex justify-center items-center">
              <div className="flex flex-row ">
                <Spinner />
                {/* <h1 className="relative text-[#196b49] text-lg mt-40">Processing...</h1> */}

              </div>

            </div>
          }

          <table className="border border-red-200 w-full">
            <thead className="bg-[#f0f2f6] text-left">
              <tr>
                <th className="border border-gray-500 px-4 py-2">Sender ID</th>
                {/* <th className="border border-gray-500 px-4 py-2">SMS Pages</th> */}
                <th className="border border-gray-500 px-4 py-2">
                  Number of Recipients
                </th>
                <th className="border border-gray-500 px-4 py-2">SMS Type</th>
                <th className="border border-gray-500 px-4 py-2">Total Cost</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="border border-gray-500 px-4 py-2">{gottenResults.senderId}</td>
                {/* <td className="border border-gray-500 px-4 py-2">1</td> */}
                <td className="border border-gray-500 px-4 py-2">{responseArray?.length}</td>
                <td className="border border-gray-500 px-4 py-2">{gottenResults.route === 'MKT' ? 'MKT' : 'TRX'}</td>
                <td className="border border-gray-500 px-4 py-2">{gottenResults.route === 'MKT' ? userData.charges_open * responseArray?.length : userData.charges_coop * responseArray?.length}NGN</td>
              </tr>
            </tbody>
          </table>

          <div className="bottom">
            <div className="top_bar">
              <div className="first">
                <form>
                  <label>Show</label>
                  <input
                    type="number"
                    value={value}
                    onInput={handleInputChange}
                  />
                  entries
                </form>
                <p>CSV</p>
                <p>Excel</p>
                <p>Print</p>
              </div>
            </div>

            <table className="w-full mt-20">
              <thead className="bg-[#f0f2f6] text-left">
                <tr>
                  <th className="px-4 py-2">Sender ID</th>
                  <th className="px-4 py-2">Recipient</th>
                  <th className="px-4 py-2">Status</th>
                  <th className="px-4 py-2">Description</th>
                  <th className="px-4 py-2">Time stamp</th>
                </tr>
              </thead>
              <tbody className="">
                {responseArray?.map((collection, index) => (
                  <tr key={index}>
                    <td className="px-4 py-2 text-[#313545]">
                      {collection.sid}
                    </td>
                    <td className="px-4 py-2 text-[#454a5f]">
                      {collection.recipient}
                    </td>
                    <td className="px-4 py-2 text-[#0182fa]">
                      {collection.status}
                    </td>
                    <td className="px-4 py-2 text-[#454a5f]">
                      {collection.response}
                    </td>
                    <td className="px-4 py-2 text-[#737992]">
                      {formatDate(collection.submitted)}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </main>
    </section>
  );
}
