import React, { useEffect, useState } from 'react'
import './styles.scss'
import { useNavigate } from 'react-router-dom'
import StyledInput from '../../../Components/StyledInput'
import PrimaryButton from '../../../Components/PrimaryButton'
import { url } from '../../../utils/utils'

const Profile = () => {
  const [userData, setUserData] = useState({})
  const [loading, setLoading] = useState(false)
  const UserToken = localStorage.getItem("token");
  const navigate = useNavigate();
  const [gender, setGender] = useState('Male');
  const [country, setCountry] = useState('Nigeria');
  const [ispassword, setIspassword] = useState(false);
  const [passForm, setPassForm] = useState({
    current: "",
    newPass: "",
    confirmPass: ""
  });

  const [formErr, setFormErr] = useState("");

  useEffect(() => {
    const usrDT = localStorage.getItem('userData')

    if (usrDT) {
      const retrievedData = JSON.parse(usrDT);

      setUserData(retrievedData)
      // getBalance(retrievedData.uid)
      //   console.log(retrievedData)

    }

  }, [])

  const changeIsPassAct = () => {
    if (ispassword) {
      setIspassword(false)
    } else {
      setIspassword(true)
    }
  }

  const handlePassChange = (e) => {
    const { name, value } = e.target;
    setPassForm(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const updatePass = () => {
    setFormErr("")

    if (passForm.newPass !== passForm.confirmPass) {
      setFormErr("New Password and Confirm Password does not match. Please try again")
      // return;
    }else{
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", `Bearer ${UserToken}`);
  
      const raw = JSON.stringify({
        oldPass: passForm.current,
        password: passForm.confirmPass,
      });
  
      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow"
      };
  
      fetch(`${url}/api/users/update?uid=${userData.uid}`, requestOptions)
        .then((response) => response.json())
        .then((result) => {
          // console.log(result)
          if (result.error) {
            setFormErr(result.message);
          } else {
            setFormErr("Password updated");
  
          }
  
        })
        .catch((error) => {
          setFormErr(error.message);
          // console.error(error.message)
        });
  
    }

    
  }

  return (
    <div className='topup-main-container'>
      <div className='head-line flex'>
        <p className='head-1'>Profile</p>
        <p className='head-2'>Dashboard</p>
        <p className='head-2'>{'>'}</p>

        <p className='head-2'>Settings</p>
        <p className='head-2'>{'>'}</p>

        <p className='head-2'>Profile</p>
      </div>

      <div className='topup-content-container profile'>
        <div className='bg-[#f0f2f6] p-5 w-full flex flex-row rounded-sm btn-block'>
          <div className={!ispassword ? 'border-2 border-[var(--primary-color)] rounded-3xl py-2 px-6 mr-4 btn-mb'
            : 'border border-[var(--primary-grey)] rounded-3xl  py-2 px-6 mr-4 cursor-pointer btn-mb'} onClick={changeIsPassAct}>
            <p className={!ispassword ? 'text-[var(--primary-color)]' : 'text-[var(--primary-grey)]'}>Personal Information</p>
          </div>

          <div className={ispassword ? 'border-2 border-[var(--primary-color)] rounded-3xl py-2 px-6 mr-4 btn-mb'
            : 'border border-[var(--primary-grey)] rounded-3xl  py-2 px-6 mr-2 cursor-pointer btn-mb'} onClick={changeIsPassAct}>
            <p className={ispassword ? 'text-[var(--primary-color)]' : 'text-[var(--primary-grey)]'}>Change Password</p>
          </div>

        </div>
        {ispassword ?
          <>
            <p className='my-4 text-lg font-bold'>Change Password</p>

            {formErr && <p className='text-[red] p-2'>{formErr}</p>}

            <div className='p-6 bg-[#f0f2f6] rounded-sm'>
              <div className='bg-[#f0f2f6] rounded-sm flex flex-row items-center w-[48%] justify-between btn-full btn-block'>
                <p className='mr-4 text-lg font-bold head-full'>Current Password</p>
                <div className='w-[68%] btn-full'>
                  <StyledInput
                    type={'password'}
                    name="current"
                    value={passForm.current}
                    handleChange={handlePassChange} />

                </div>
              </div>

              <div className='bg-[#f0f2f6] rounded-sm flex flex-row items-center w-[48%] justify-between btn-full btn-block'>
                <p className='mr-4 text-lg font-bold head-full'>New Password</p>
                <div className='w-[68%] btn-full'>
                  <StyledInput
                    type={'password'}
                    name="newPass"
                    value={passForm.newPass}
                    handleChange={handlePassChange} />

                </div>
              </div>

              <div className='bg-[#f0f2f6] rounded-sm flex flex-row items-center w-[48%] justify-between btn-full btn-block'>
                <p className='mr-4 text-lg font-bold head-full'>Confirm Password</p>
                <div className='w-[68%] btn-full'>
                  <StyledInput
                    type={'password'}
                    name="confirmPass"
                    value={passForm.confirmPass}
                    handleChange={handlePassChange} />

                </div>
              </div>

              <div className='w-[12%] mt-2 btn-full'>
                <PrimaryButton text={'Update Password'} color={'var(--primary-color)'} handleClick={updatePass} />

              </div>

            </div>
          </>
          :
          <>
            <p className='my-4 text-lg font-bold'>User Persornal Information</p>

            <div className='p-6 bg-[#f0f2f6] rounded-sm'>
              <div className='flex flex-row items-center'>
                <div className='mr-4'>
                  <div className='rounded-full bg-[var(--primary-color)] flex flex-row w-20 h-20 items-center justify-center'>
                    <p className='text-[white] text-3xl capitalize'>
                      {userData.username?.slice(0, 1)}

                    </p>
                  </div>
                </div>
                <div>
                  <p className='capitalize font-bold'>{userData.username}</p>
                  <p className='text-[var(--primary-color)] font-bold cursor-pointer'>{userData.email}</p>
                  <div className='h-[0.2px] w-full bg-[var(--primary-color)]' />

                </div>

              </div>
              <div className='h-[0.2px] w-full bg-[var(--primary-border-grey)] my-4' />

              <div className='flex justify-between items-center flex-wrap flex-1-0'>
                <div className='w-[48%] btn-full'>
                  <StyledInput label={'First Name'} disabled value={userData.lastname || 'N/A'} />

                </div>

                <div className='w-[48%] btn-full'>
                  <StyledInput label={'Last Name'} disabled value={userData.lastname || 'N/A'} />

                </div>

                <div className='w-[48%] btn-full'>
                  <p className='text-[var(--primary-grey)]'>Gender</p>

                  <select className='w-full border rounded-lg mt-2 p-2 text-[var(--primary-grey)]' id="yourSelector" value={gender} onChange={(e) => setGender(e.target.value)}>
                    {/* <option value="">Select...</option> */}
                    <option value="Male">Male</option>
                    <option value="Famale">Female</option>
                    {/* <option value="INT">International</option> */}
                  </select>


                </div>

              </div>

            </div>

            <p className='font-bold text-lg my-4'>Contact Information</p>

            <div className='p-6 bg-[#f0f2f6] rounded-sm'>

              <div className='flex justify-between items-center flex-wrap flex-1-0'>
                <div className='w-[48%] btn-full'>
                  <StyledInput label={'Contact phone'} disabled value={userData.phone} />

                </div>

                <div className='w-[48%] btn-full'>
                  <StyledInput label={'Email Address'} disabled value={userData.email} />

                </div>

                <div className='w-[48%] btn-full'>
                  <p className='text-[var(--primary-grey)]'>Country</p>

                  <select className='w-full border rounded-lg mt-2 p-2 text-[var(--primary-grey)]' id="yourSelector" value={country} onChange={(e) => setCountry(e.target.value)}>
                    {/* <option value="">Select...</option> */}
                    <option value="NG">Nigeria</option>
                    <option value="USA">United States</option>
                    <option value="Gh">Ghana</option>
                    <option value="SA">South Africa</option>
                    <option value="CAN">Canada</option>
                    <option value="CHN">China</option>

                  </select>


                </div>

                <div className='w-[48%] btn-full'>
                  <StyledInput label={'City'} disabled value={userData.lastname || 'N/A'} />

                </div>

              </div>

              <div className='w-[18%] mt-2 btn-full'>
                <PrimaryButton text={'Update Profile'} color={'var(--primary-color)'} />

              </div>
            </div>
          </>
        }


      </div>
    </div>
  )
}

export default Profile