// SignupForm.js
import React, { useState } from "react";
import "./styles.scss";
import Sidebar from "../../Components/Auth/SignSideBar";
import { Link } from "react-router-dom";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import Popup from "../../Components/PopUp";
import Spinner from "../../Components/Spinner";
import { url } from "../../utils/utils";
import StyledInput from "../../Components/StyledInput";
import PrimaryButton from "../../Components/PrimaryButton"


const SignupForm = () => {
  const navigate = useNavigate();

  // State variables to manage form data, errors, and success message
  const [formData, setFormData] = useState({
    username: "",
    email: "",
    password: "",
    confirmPassword: "",
    phoneNumber: "",
    country: null,
  });

  const [formErrors, setFormErrors] = useState({
    username: "",
    email: "",
    password: "",
    confirmPassword: "",
    phoneNumber: "",
    country: "",
  });

  const [showPopup, setShowSuccess] = useState(false);
  const [checkBox, setCheckbox] = useState(false)
  const [showSpinner, setShowSpinner] = useState(false);

  // Event handler for general input changes
  const handleInputChange = (e) => {

    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Event handler for country selection changes using Select component
  const handleCountryChange = (selectedOption) => {
    setFormData({ ...formData, country: selectedOption.label });
  };


  // Event handler for form submission
  const handleSubmit = (e) => {
    e.preventDefault();

    // Object to store form validation errors
    // const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/;
    const errors = {};

    // Validate each form field
    if (formData.username.trim() === "") {
      errors.username = "Username is required";
    }

    const password = formData.password;

    if (password.length < 8) {
      errors.password = 'Password must be at least 8 characters long';
    }

    if (!/[a-z]/.test(password)) {
      errors.password = 'Password must contain at least one lowercase letter';
    }

    if (!/[A-Z]/.test(password)) {
      errors.password = 'Password must contain at least one uppercase letter';
    }

    if (!/\d/.test(password)) {
      errors.password = 'Password must contain at least one digit';
    }

    // // Finally, check if the password meets the complex regex criteria
    // if (!passwordRegex.test(password)) {
    //   errors.password = 'Password does not meet complexity requirements';
    // }

    if (formData.confirmPassword.trim() === "") {
      errors.confirmPassword = "Please confirm your password";
    }


    if (formData.password !== formData.confirmPassword) {
      errors.confirmPassword = "Passwords do not match";
    }

    if (formData.phoneNumber.trim() === "") {
      errors.phoneNumber = "Phone number is required";
    }

    if (formData.email.trim() === "" || !/\S+@\S+\.\S+/.test(formData.email)) {
      errors.email = "Email is not valid";
    }

    if (!formData.country) {
      errors.country = "Country is required";
    }

    if (!checkBox) {
      errors.country = "You must accept the T&C"
    }
    // 1Ayomideodubela



    // If there are validation errors, update the state
    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      // console.log({ errors })

    } else {
      setShowSpinner(true)

      // console.log('signing up')

      var myHeaders = new Headers();
      myHeaders.append("api-auth-key", "mobile_tech_solution_1234567890987654321");
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        "email": formData.email,
        "password": formData.password,
        "username": formData.username,
        "phone": formData.phoneNumber,
        "country": formData.country
      });

      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
      };

      const errors = {}

      fetch(`${url}/api/users/register`, requestOptions)
        .then(response => response.json())
        .then(result => {
          if (result.message === "Email exists") {
            errors.email = 'This email is taken. Kindly sign in to your account'
            setFormErrors(errors)
            setShowSpinner(false)
          } else {
            // console.log('result', result)
            setShowSpinner(false)

            // If no errors, show success message

            setShowSuccess(true);

            // Hide the success message after 2 seconds
            setTimeout(() => {
              setShowSuccess(false);

              // Navigate to the main dashboard upon successful registration
              navigate("/SignIn");

              setFormData({
                // Clear the form data and errors after submission
                username: "",
                email: "",
                password: "",
                confirmPassword: "",
                phoneNumber: "",
                country: null,
              });
              setFormErrors({
                username: "",
                email: "",
                password: "",
                confirmPassword: "",
                phoneNumber: "",
                country: "",
              });
            }, 2000);
          }
        })
        .catch(error => {
          // console.log('error', error)
          setShowSpinner(false)
        });
    }
  };

  return (
    <div className="auth2-page">
      <Sidebar />
      <div className="form-container">
        {/* Form for user registration */}
        <form onSubmit={handleSubmit} className="form" autoComplete="on">
          <div className="head">
            <h2>Sign Up</h2>
            <p>Create an account to get started</p>
          </div>

          <StyledInput
            label={'Username'}
            handleChange={handleInputChange}
            value={formData.username}
            type='name'
            formError={formErrors.username}
            name="username"
            id="username"
            required
          />


          <StyledInput
            label={'Email Address'}
            handleChange={handleInputChange}
            value={formData.email}
            type='email'
            formError={formErrors.email}
            name="email"
            id="email"
            required
          />

          <StyledInput
            label={'Phone Number'}
            handleChange={handleInputChange}
            value={formData.phoneNumber}
            type='phone'
            formError={formErrors.phoneNumber}
            name="phoneNumber"
            id="phoneNumber"
            required
          />


          <StyledInput
            label={'Password'}
            handleChange={handleInputChange}
            value={formData.password}
            type='password'
            formError={formErrors.password}
            name="password"
            id="password"
            // password
            required
          />

          <div className="info">
            <p>Password should be atleast 8 Characters long, 1 cap letter and number included.</p>
          </div>

          <StyledInput
            label={'Confirm Password'}
            handleChange={handleInputChange}
            value={formData.confirmPassword}
            type='password'
            formError={formErrors.confirmPassword}
            name="confirmPassword"
            id="confirmPassword"
            required
          />

          {/* Select component for  country */}
          <Select
            className="select-country"
            onChange={handleCountryChange}
            options={[
              { value: "NG", label: "Nigeria" },
              { value: "USA", label: "United States" },
              { value: "Gh", label: "Ghana" },
              { value: "SA", label: "South Africa" },
              { value: "CAN", label: "Canada" },
              { value: "N/L", label: "Not Listed" },
            ]}
            placeholder="Select your country"
          />

          {formErrors.country && (
            <span className="error" style={{ color: 'red', fontSize: 14 }}>{formErrors.country}</span>
          )}

          <div className="checkbox-group">
            <input type="checkbox" required id="termsCheckbox" name="termsCheckbox" onChange={(ch) => setCheckbox(ch.target.checked)} />
            <label htmlFor="termsCheckbox">
              I agree to the <Link>terms of service</Link> and <Link>privacy policy</Link>
            </label>
          </div>

          {/* <button type="submit">Submit</button> */}
          <PrimaryButton
            type="submit"
            text={'Sign Up'}
            color={'var(--primary-color)'}
          />

          <p className="already-have-account">
            Already have an account? <Link to="/SignIn">Sign in</Link>
          </p>
        </form>

        {showSpinner && (
          <Spinner />
        )}

        {/* Success popup */}
        {showPopup && (
          <Popup
            message="Your account had been created successfuly!"
            onClose={() => setShowSuccess(false)}
            show={showPopup}
            title={'Authentication'}
          />
        )}
      </div>
    </div>
  );
};

export default SignupForm;
