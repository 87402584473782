import React, { useEffect, useState } from 'react'
import './styles.scss'
import StyledInput from '../../../Components/StyledInput'
import PrimaryButton from '../../../Components/PrimaryButton'
import { useFlutterwave, closePaymentModal } from 'flutterwave-react-v3';
import { url } from '../../../utils/utils';
import { useNavigate } from 'react-router-dom';
import shortid from 'shortid';
import { usePaystackPayment, PaystackButton, PaystackConsumer } from 'react-paystack';
const generateRandomNumber = () => {
    return Math.floor(100000 + Math.random() * 900000);
};

const Topup = () => {
    const navigate = useNavigate();
    const [stage, setStage] = useState('Top up')
    const [amount, setAmount] = useState(0)
    const [balance, setBalance] = useState(0)
    const [err, setErr] = useState('')
    const [userData, setUserData] = useState({})
    const [loading, setLoading] = useState(false)
    const UserToken = localStorage.getItem("token");
    const [shortId, setUUid] = useState('')

    const sixDigitNumber = generateRandomNumber();

    useEffect(() => {
        const usrDT = localStorage.getItem('userData')

        if (usrDT) {
            const retrievedData = JSON.parse(usrDT);

            setUserData(retrievedData)
            getBalance(retrievedData.uid)
            //   console.log(retrievedData)

        }

        setUUid(generateRandomNumber())

    }, [])

    const getBalance = (uid) => {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", `Bearer ${UserToken}`);

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };


        fetch(`${url}/api/users/get-balance?uid=${uid}`, requestOptions)
            .then(response => response.json())
            .then(result => {

                if (result.status === 'bad') {
                    navigate("/SignIn");
                    return;
                }
                setBalance(result.balance)

            })
            .catch(error => console.log('error', error));
    }

    // const config = {
    //     public_key: 'FLWPUBK_TEST-5a5545b45c2f0cb866c2da78ee97e756-X',
    //     tx_ref: Date.now(),
    //     amount: amount + (7.5 / 100) * amount,
    //     currency: 'NGN',
    //     payment_options: 'card,mobilemoney,ussd',
    //     customer: {
    //         email: userData.email || '',
    //         phone_number: userData.phone || '',
    //         name: userData.username || '',
    //     },
    //     customizations: {
    //         title: 'Account Top up',
    //         description: 'Smarthive Acconut top up',
    //         logo: 'https://st2.depositphotos.com/4403291/7418/v/450/depositphotos_74189661-stock-illustration-online-shop-log.jpg',
    //     },
    // };

    // useFlutterwave(config)

    // const handleFlutterPayment = useFlutterwave(config);

    // handleFlutterPayment({
    //     callback: (response) => {
    //         transactionCallback(response)
    //         setLoading(false)

    //         closePaymentModal() // this will close the modal programmatically
    //     },
    //     onClose: () => {
    //         setLoading(false)
    //     },
    // });


    const config = {
        reference: shortId + '_' + (new Date()).getTime().toString(),
        email: userData.email || '',
        amount: (amount * 100) + (7.5 / 100) * (amount * 100), //Amount is in the country's lowest currency. E.g Kobo, so 20000 kobo = N200
        // publicKey: process.env.Paystack_Live_Key,
        publicKey: 'pk_live_4f1ad904781210dac44ae6fdf07fcd3fa17f6be5',
    };

    // const initializePayment = usePaystackPayment(config);

    // you can call this function anything
    const onSuccess = (reference) => {
        // Implementation for whatever you want to do with reference and after success call.
        // console.log({ reference });
        transactionCallback(reference)
    };

    // you can call this function anything
    const onClose = () => {
        // implementation for  whatever you want to do when the Paystack dialog closed.
        setLoading(false)
        // console.log('closed')
    }

    const componentProps = {
        ...config,
        text: 'Pay',
        onSuccess: (reference) => onSuccess(reference),
        onClose: onClose,
    };


    const changeStage = (stg) => {

        if (stg === 'Top up') {
            setStage(stg)
            setUUid(shortid.generate())

        } else
            if (amount === 0) {
                setErr('Amount cannot be 0')
            } else if (amount < 100) {
                setErr('Amount must not be less than N100')

            } else {
                setStage(stg)
                setErr('')
            }
    }

    const transactionCallback = (response) => {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", `Bearer ${UserToken}`);

        var raw = JSON.stringify({
            ...response,
            uid: userData.uid,
            invoiceNo: shortId,
            def_amount: amount,
            created_at: (new Date())
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };


        fetch(`${url}/api/users/transaction?uid=${userData.uid}`, requestOptions)
            .then(response => response.json())
            .then(result => {

                // console.log(result)
                getBalance(userData.uid)

                // setBalance(result.balance)

            }).then(() => {
                setLoading(false)
                setStage('Top up')
                setAmount(0)
            })
            .catch(error => {
                console.log('error', error)
                setLoading(false)
                setAmount(0)
            });
    }


    // const date = new Date()

    function formatDateToYYMMDD(date) {
        const formattedDate = date.toLocaleDateString('en-GB', {
            year: '2-digit',
            month: '2-digit',
            day: '2-digit'
        });
        return formattedDate;
    }

    return (
        <div className='topup-main-container'>
            {loading && (
                <div className='fixed w-full h-full opacity-[50%] bg-black z-[100] inset-0'/>
            )}
            <div className='head-line flex'>
                <p className='head-1'>Account Topup</p>
                <p className='head-2'>Dashboard</p>
                <p className='head-2'>{'>'}</p>

                <p className='head-2' onClick={() => changeStage('Top up')}>Transaction</p>
                <p className='head-2'>{'>'}</p>

                <p className='head-2'>{stage}</p>

            </div>

            <div className='topup-content-container'>

                {stage === 'Top up' &&
                    <div className='transaction-container'>
                        <div className='topup-content flex'>
                            <div className='left-cont'>
                                <p className='title'> Billing Information</p>

                                <div className='cont'>
                                    <p>Current Balance</p>

                                    <p className='amount'>&#8358;{balance?.toFixed(2)}</p>
                                </div>
                            </div>

                            <div className='right-cont'>
                                <p className='head'> Fund Account</p>

                                <StyledInput label={'Amount'} placeholder='Amount in Naira' type={'number'} handleChange={(e) => setAmount((parseInt(e.target.value)))} formError={err} />

                                <PrimaryButton text={'Fund Account'} color={'var(--primary-color)'} handleClick={() => changeStage('Checkout')} />
                            </div>
                        </div>
                    </div>
                }

                {stage === 'Checkout' &&
                    <div className='transaction-container'>
                        <p style={{ textAlign: 'center', color: 'var(--primary-color)', fontWeight: 600, fontSize: '1.5rem', marginTop: -20, marginBottom: 20 }}>CHECKOUT</p>

                        <div className='topup-content flex'>

                            <div className='left-cont chk'>
                                <p className='head'> SmartHiveTechSolution</p>
                                <p>support@smarthivetechsolution.com</p>
                                <p>+234 902 423 2473</p>

                                <div className='sec-2'>
                                    <p className='hh'>Invoice to :</p>
                                    <p className='head'>{userData.username}</p>
                                    <p>{userData.email}</p>
                                    <p>{userData.phone}</p>
                                </div>
                            </div>

                            <div className='right-cont chk'>
                                <p className='head'> Order Summary</p>

                                <div style={{ marginBottom: 20 }}>
                                    <div className='flex inv'>
                                        <p>Invoice No:</p>

                                        <p>IN{shortId}</p>
                                    </div>

                                    <div className='flex inv'>
                                        <p>Date</p>

                                        <p>{formatDateToYYMMDD(new Date())}</p>
                                    </div>

                                    <div className='flex inv'>
                                        <p>Amount</p>

                                        <p>&#8358;{amount.toLocaleString()}</p>
                                    </div>

                                    <div className='flex inv'>
                                        <p>VAT{'(7.5%)'}</p>

                                        <p>&#8358;{((7.5 / 100) * amount).toLocaleString()}</p>
                                    </div>

                                    <div className='flex inv'>
                                        <p>Total</p>

                                        <p>&#8358;{((amount + (7.5 / 100) * amount)).toLocaleString()}</p>
                                    </div>

                                    <div className='flex inv'>
                                        <p>Est SMS Unit:</p>

                                        <p>{(amount/2.9).toFixed()} Units</p>
                                    </div>

                                    {/* <div className='flex inv'>
                                    <p>Est Sms Unit:</p>

                                    <p>{12} Unit</p>
                                </div> */}
                                </div>

                                <PaystackConsumer {...componentProps} >
                                    {({ initializePayment }) =>
                                        <PrimaryButton text={'Pay'} 
                                        handleClick={()=> {
                                            setLoading(true)
                                            initializePayment(onSuccess, onClose)
                                        }}
                                        color={'var(--primary-color)'} 
                                        loading={loading} />
                                    }
                                </PaystackConsumer>
                            </div>
                        </div>

                        <div className='items-center justify-center flex flex-row'>
                        <div className='bg-[#ebebeb] text-grey mt-8 p-4 rounded-md text-sm w-[90%]'>
                        All bank payments should be directed to Smarthivetech Solution Ltd, account number 5402088760, at Providus Bank. When making the payment, please include your username/email in the payment description for both bank transfers and cash deposits. For verification purposes, kindly send your proof of payment to <a className='text-[green]' href='mailto:support@smarthivetechsolution.com'>support@smarthivetechsolution.com</a>.
                        </div>
                         </div>
                    </div>
                }

            </div>
        </div>
    )
}

export default Topup