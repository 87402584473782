import React from 'react'
import './styles.scss'
import { Player, Controls } from '@lottiefiles/react-lottie-player';
import progress from "../../assets/animations/progress.json";


const Spinner = () => {
    return (
        <div className='spinner-container'>
            <Player
                autoplay
                loop
                src={progress}
                style={{ height: '30%', width: '30%' }}
            >
                <Controls visible={false} buttons={['play', 'repeat', 'frame', 'debug']} />
            </Player>
        </div>
    )
}

export default Spinner