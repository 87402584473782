//hosts
const hosts = {
  liveUrl: "https://api.smarthivesms.com",
  onRender: "https://smsapi-sur2.onrender.com",
  networkHost: "http://192.168.8.192:6008",
  localHost: "http://localhost:6008",
};

export const url = hosts.onRender;

export const formatDate = (inputDateString) => {
  const inputDate = new Date(inputDateString);

  // Format the date
  const day = inputDate.getDate().toString().padStart(2, "0");
  const month = (inputDate.getMonth() + 1).toString().padStart(2, "0");
  const year = inputDate.getFullYear().toString().slice(2);
  const hours = inputDate.getHours().toString().padStart(2, "0");
  const minutes = inputDate.getMinutes().toString().padStart(2, "0");
  const seconds = inputDate.getSeconds().toString().padStart(2, "0");

  return `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
};

export const formatCurrency = (amount) => {
  // Create a NumberFormat object for Nigerian Naira (₦) currency
  const formatter = new Intl.NumberFormat('en-NG', {
      style: 'currency',
      currency: 'NGN',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
  });

  return formatter.format(amount);
};

