import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { formatDate, url } from '../../../utils/utils';
import * as Icon from 'react-feather';
import './styles.scss'
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import PrimaryButton from '../../../Components/PrimaryButton';
import StyledInput from '../../../Components/StyledInput';
const ContactGroup
    = () => {

        const navigate = useNavigate()
        const [queryLimit, setqueryLimit] = useState(25);
        const [queryPages, setqueryPages] = useState(1);
        const [queryPagesCount, setqueryPagesCount] = useState(0);
        const [subsList, setSubs] = useState([]);
        const [actionBtn, setActionBtn] = useState(false);
        const [actionBtnId, setActionBtnId] = useState(null)
        const [modal, setModal] = useState(false)
        const [isEdit, setIsEdit] = useState(false)
        const [editData, setEditData] = useState({})
        const [loading, setLoading] = useState(false)
        const UserToken = localStorage.getItem("token");
        const [userData, setUserData] = useState({})
        const [route, setRoute] = useState('MKT')
        const [formData, setFormData] = useState({
            callback_url: "",
            name: "",
        })


        useEffect(() => {
            const usrDT = localStorage.getItem('userData')
            if (usrDT) {
                const retrievedData = JSON.parse(usrDT);

                setUserData(retrievedData)
                fetchContactGroups(retrievedData.uid)
                //   console.log(retrievedData)

            }

        }, [])

        const handleChange = (event) => {
            const val = event.target.value
            setRoute(val);

        };

        const [groupName, setGroupName] = useState('');
        const [description, setDescription] = useState('');
        const [file, setFile] = useState(null);

        const handleSubmit = async (event) => {
            event.preventDefault();

            const formData = new FormData();
            formData.append('contacts', file);
            formData.append('groupName', groupName);
            formData.append('description', description);

            try {
                const response = await fetch(`${url}/api/contacts/creategroup?uid=${userData.uid}`, {
                    method: 'POST',
                    body: formData,
                });

                const result = await response.json();

                if (response.status === 200) {
                    setModal(false)
                    fetchContactGroups(userData.uid)
                }
            } catch (error) {
                console.error('Error:', error);
            }
        };

        const fetchContactGroups = async (uid) => {
            try {
                const response = await fetch(`${url}/api/contacts/contactgroup?uid=${uid}`, {
                    method: 'GET',

                });
                const result = await response.json();
                console.log(result)

                if (response.ok) {
                    console.log('Contact Groups:', result);


                    setSubs(result.data)
                } else {
                    // if (result.status === 'bad') {
                    //     navigate("/SignIn");
                    //     return;
                    // }
                    console.error('Error:', result.message || result.error);
                }
            } catch (error) {
                console.error('Fetch error:', error);
            }
        };


        const handleEditChange = (e) => {
            const { name, value } = e.target;
            setFormData(prevState => ({
                ...prevState,
                [name]: value
            }));
        };

        const downloadCsvFile = (fileUrl, fileName) => {
            fetch(fileUrl)
                .then(response => response.blob())
                .then(blob => {
                    const url = window.URL.createObjectURL(blob);
                    const link = document.createElement('a');
                    link.href = url;
                    link.download = fileName;
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                    window.URL.revokeObjectURL(url);
                })
                .catch(error => console.error('Error downloading file:', error));
        };


        return (
            <>
                {modal &&
                    <div className='fixed w-screen h-screen z-[20]'>
                        <div className='backdrop-blur fixed w-full h-full bg-black/20' />
                        <div className=' w-full h-full flex relative flex-col items-center justify-center'>
                            <div className='bg-white p-6 w-96 relative'>
                                <p className='font-bold'>Create New Contact Group</p>
                                <div className='absolute top-0 right-0 m-6 bg-[var(--primary-border-grey)] rounded-full p-1' onClick={() => {
                                    setModal(false)
                                    setFormData({})
                                    setRoute("MKT")
                                }}>
                                    <Icon.X size={15} color='var(--primary-grey)' />
                                </div>
                                <form onSubmit={handleSubmit}>
                                    <StyledInput
                                        label={'Name'}
                                        value={formData.keyname}
                                        name="keyname"
                                        id="groupName"
                                        handleChange={(e) => setGroupName(e.target.value)}
                                        required />

                                    <label htmlFor="contacts">CSV File:</label>
                                    <input
                                        type="file"
                                        id="contacts"
                                        accept=".csv"
                                        onChange={(e) => setFile(e.target.files[0])}
                                        required
                                    />

                                    <StyledInput
                                        label={'Description'}
                                        value={formData.keyname}
                                        name="keyname"
                                        id="description"
                                        handleChange={(e) => setDescription(e.target.value)}
                                        required />


                                    <div className='mt-6'>
                                        <PrimaryButton text={isEdit ? 'Update Contacts' : 'Create Contacts'} color={'var(--primary-color)'} primary type="submit" loading={loading} />

                                    </div>


                                </form>

                                {/* <a href='http://localhost:3000/src/assets/files/builder.png' download>
                                   DDD 
                                </a> */}

                                {/* <button onClick={() => downloadCsvFile('http://localhost:3000/src/assets/files/builder.png', 'contact-group.png')}>
                                    Download Sample
                                </button> */}

                                <p className='text-[red]'>Note: Your contact group file should be a csv format, all numbers must be in a single column</p>
                            </div>
                        </div>
                    </div>
                }
                <div className='topup-main-container'>
                    <div className='head-line flex'>
                        <p className='head-1'>Manage Contact Groups</p>
                        <p className='head-2'>Dashboard</p>
                        <p className='head-2'>{'>'}</p>

                        <p className='head-2'>Settings</p>
                        <p className='head-2'>{'>'}</p>

                        <p className='head-2'>Contact Group</p>

                    </div>

                    <div className='topup-content-container history'>

                        <div className='flex justify-between'>


                            <PrimaryButton text={'New Contact Group'}
                                icon={'Plus'}
                                color={'var(--primary-color)'}
                                primary
                                handleClick={() => setModal(true)}
                            />


                        </div>
                        <div className='comp-container-api'>
                            <div className='comp-container-inner'>
                                <div className='table-container'>
                                    <table id="transact-history">
                                        <thead>
                                            <tr>
                                                <th class="selector">
                                                    <input type="checkbox" id="selectRow1" class="select-row" />
                                                </th>
                                                <th>S/N</th>
                                                <th>Name</th>
                                                <th>contacts</th>
                                                <th>Description</th>
                                                <th>Action</th>

                                            </tr>
                                        </thead>
                                        <tbody>
                                            {subsList && subsList.length > 0 && subsList.slice().reverse().map((item, index) => (
                                                <tr key={index}>
                                                    <td class="selector">
                                                        <input type="checkbox" id="selectRow1" class="select-row" />
                                                    </td>
                                                    <td>
                                                        {index + 1}
                                                    </td>
                                                    <td>{item.groupName}</td>
                                                    <td>{item.numbers.length}</td>
                                                    <td>{item.description}</td>
                                                    <td><PrimaryButton
                                                        text={'Edit'}
                                                        color={'var(--primary-color)'}
                                                        disabled
                                                        handleClick={() => {
                                                            setIsEdit(true)
                                                            setModal(true)
                                                            setEditData(item)
                                                            formData.keyname = item.keyname
                                                            formData.callback_url = item.callback_url
                                                            setRoute(item.route)
                                                        }} /></td>

                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>

                                <div className='flex bottom-qry-container'>

                                    <div className='flex'>
                                        <div className='selector-container'>
                                            <select id="yourSelector" value={queryLimit} onChange={handleChange}>
                                                {/* <option value="">Select...</option> */}
                                                <option value="25">25</option>
                                                <option value="50">50</option>
                                                <option value="100">100</option>
                                                <option value="200">200</option>
                                                {/* Add more options as needed */}
                                            </select>

                                        </div>
                                        {/* <span> {queryPages} - {queryPagesCount} of {queryPagesCount}</span> */}

                                    </div>

                                    <div className='page-nav-container'>
                                        <span>{'<'}</span>
                                        <span className='count'>{queryPages}</span>
                                        <span>{'>'}</span>

                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </>
        )
    }

export default ContactGroup
