import React from "react";
import TopNav from "../Components/Navigation/TopNav";
import LeftNav from "../Components/Navigation/LeftNav";
import Footer from "../Components/Navigation/Footer";

const DashBoardLayout = (props) => {
  return (
    <div className="dashboard-layout">
        <TopNav />
        {props.children}
        {/* <Footer /> */}
    </div>
  );
};

export default DashBoardLayout;
