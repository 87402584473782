import React, { useEffect, useState } from "react";
import hamburger from "../../assets/hamburger.svg";
import refresh from "../../assets/refresh.svg";
import cross from "../../assets/cross.png";
import "./style.scss";
import * as Icon from 'react-feather';
import { useNavigate } from "react-router-dom";
import { checkAuth, useCheckAuth } from "../../utils/checkAuth";
import Chart from "../../Components/Chart";
import StyledSelector from "../../Components/StyledSelector";
import { formatCurrency, url } from "../../utils/utils";

export default function Dashboard() {
  const navigate = useNavigate();
  const [userData, setUserData] = useState({})
  const [totalSmsToday, settotalSmsToday] = useState('')
  const [totaldelivToday, settotaldelivToday] = useState('')
  const [totalrejcToday, settotalrejcToday] = useState('')
  const UserToken = localStorage.getItem("token");

  const [delivStat, setDelivStat] = useState([])
  const [undelivStat, setUndelivStat] = useState([])
  const [rejectedStat, setRejectedStat] = useState([])
  const [balance, setBalance] = useState(0)

  const checkAuth = useCheckAuth();

  const getBalance = (uid) => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${UserToken}`);

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };


    fetch(`${url}/api/users/get-balance?uid=${uid}`, requestOptions)
      .then(response => response.json())
      .then(result => {

        if (result.status === 'bad') {
          navigate("/SignIn");
          return;
        }
        setBalance(result.balance)

      })
      .catch(error => console.log('error', error));
  }

  useEffect(() => {
    checkAuth().then(() => {
      const usrDT = localStorage.getItem('userData')

      if (usrDT) {
        const retrievedData = JSON.parse(usrDT);

        setUserData(retrievedData)
        getBalance(retrievedData.uid)

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("api-auth-key", "sleeng1234567890987654321");

        var requestOptions = {
          method: 'GET',
          headers: myHeaders,
          redirect: 'follow'
        };


        //   if(alertStat === true){
        fetch(`${url}/api/users/get-total-sms?uid=${retrievedData.uid}`, requestOptions)
          .then(response => response.json())
          .then(result => {
            // console.log(result)
            settotalSmsToday(result.data)

          })
          .catch(error => console.log('error', error));

        fetch(`${url}/api/users/get-total-deliv?uid=${retrievedData.uid}`, requestOptions)
          .then(response => response.json())
          .then(result => {
            // console.log(result)
            settotaldelivToday(result.data)

          })
          .catch(error => console.log('error', error));

        fetch(`${url}/api/users/get-total-rejected?uid=${retrievedData.uid}`, requestOptions)
          .then(response => response.json())
          .then(result => {
            // console.log(result)
            settotalrejcToday(result.data)

          })
          .catch(error => console.log('error', error));


        fetch(`${url}/api/users/get-monthly-stat?uid=${retrievedData.uid}`, requestOptions)
          .then(response => response.json())
          .then(result => {
            // console.log(result)
            setDelivStat(result.data.delivStat)
            setUndelivStat(result.data.undelivStat)
            setRejectedStat(result.data.rejectedStat)

          })
          .catch(error => console.log('error', error));
      }



    })

  }, [])


  const delivData = delivStat.map(item => item.totalSMS);
  const undelivData = undelivStat.map(item => item.totalSMS);
  const rejectData = rejectedStat.map(item => item.totalSMS);

  const smsStats = [{
    title: 'Delivered',
    data: delivData,
  },
  {
    title: 'Undelivered',
    data: undelivData,
  },
  {
    title: 'Rejected',
    data: rejectData,
  }]

  const options = [
    { value: 'option1', label: 'Option 1' },
    { value: 'option2', label: 'Option 2' },
    { value: 'option3', label: 'Option 3' },
  ];

  const [selectedValue, setSelectedValue] = useState(options[0].value);

  const handleSelectorChange = (event) => {
    setSelectedValue(event.target.value);
    // Additional logic if needed
  };

  return (
    <div className="dashboard-main">
      <div className="overview-container">
        <div className="title">
          Dashboard Overview
        </div>

        <div className="wlc-name">
          Hello {userData.username}
        </div>

        <div className='card-flex flex'>
          <div className="each-flex-card">
            <div className="top flex">
              <Icon.MessageCircle color="white" size={20} />
              <span>Total SMS Sent</span>
            </div>
            <div className="middle">
              {totalSmsToday ? totalSmsToday : '0'}
            </div>
            <div className="today">
              Today
            </div>
          </div>

          <div className="each-flex-card">
            <div className="top flex">
              <Icon.XOctagon color="white" size={20} />
              <span>Total SMS Delivered</span>
            </div>
            <div className="middle">
              {totaldelivToday ? totaldelivToday : '0'}
            </div>
            <div className="today">
              Today
            </div>
          </div>

          <div className="each-flex-card">
            <div className="top flex">
              <Icon.Check color="white" size={20} />
              <span>Total SMS Failed</span>
            </div>
            <div className="middle">
              {totalrejcToday ? totalrejcToday : '0'}
            </div>
            <div className="today">
              Today
            </div>
          </div>

          <div className="each-flex-card">
            <div className="top flex">
              <Icon.DollarSign color="white" size={20} />
              <span>Account Balance</span>
            </div>
            <div className="middle">
              {formatCurrency(balance)}
            </div>

          </div>
        </div>
      </div>

      <div className="graph-container">
        <div className="flex flex-wrap">
          <div className="flex flex-wrap">
            <span>Delivery Report</span>

          </div>

          {/* <StyledSelector options={options} selectedValue={selectedValue} onChange={handleSelectorChange}/> */}
        </div>

        <Chart datas={smsStats} bar monthly />

      </div>
    </div>
  );
}
