import React, { useEffect, useState } from "react";
import axios from "axios";
import ExcelJS from "exceljs";
import "./styles.scss";
import { url } from "../../../../utils/utils";
import { DateRange } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import PrimaryButton from "../../../../Components/PrimaryButton";
import { ChevronDown, ChevronRight } from "react-feather";
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { useNavigate } from "react-router-dom";
import Spinner from "../../../../Components/Spinner";
import StyledInput from "../../../../Components/StyledInput";

const IntMsgId = () => {
  const navigate = useNavigate()
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(40);
  const [loading, setLoading] = useState(false);
  const [openDatePick, setOpenDatePick] = useState(false);
  const UserToken = localStorage.getItem("token");
  const [userData, setUserData] = useState({})
  const [msg_ids, setMsg_ids] = useState('')

  const [dateRange, setDateRange] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection'
    }
  ]);

  function getPhoneNumberCategory(phoneNumber) {
    const mtnPrefixes = ['0703', '0704', '0706', '0803', '0806', '0810', '0813', '0814', '0816', '0903', '0906', '0913', '0916'];
    const gloPrefixes = ['0705', '0805', '0807', '0811', '0815', '0905', '0915'];
    const airtelPrefixes = ['0701', '0708', '0802', '0808', '0812', '0901', '0902', '0904', '0907', '0912', '0911'];
    const nineMobilePrefixes = ['0809', '0817', '0818', '0909', '0908'];
    const mtnPrefixes2 = ['234703','234704', '234706', '234803', '234806', '234810', '234813', '234814', '234816', '234903', '234906', '234913', '234916'];
    const gloPrefixes2 = ['234705', '234805', '234807', '234811', '234815', '234905', '234915'];
    const airtelPrefixes2 = ['234701', '234708', '234802', '234808', '234812', '234901', '234902', '234904', '234907', '234912', '234911'];
    const nineMobilePrefixes2 = ['234809', '234817', '234818', '234909', '234908'];

    const formattedNumber = phoneNumber.replace(/[^\d]/g, ''); // Remove non-numeric characters

    // Check MTN prefixes
    if (mtnPrefixes.some(prefix => formattedNumber.startsWith(prefix))) {
      return 'MTN NG';
    }

    // Check GLO prefixes
    if (gloPrefixes.some(prefix => formattedNumber.startsWith(prefix))) {
      return 'GLO';
    }

    // Check Airtel prefixes
    if (airtelPrefixes.some(prefix => formattedNumber.startsWith(prefix))) {
      return 'AIRTEL';
    }

    // Check 9mobile prefixes
    if (nineMobilePrefixes.some(prefix => formattedNumber.startsWith(prefix))) {
      return '9MOBILE';
    }

    if (mtnPrefixes2.some(prefix => formattedNumber.startsWith(prefix))) {
      return 'MTN NG';
    }

    // Check GLO prefixes
    if (gloPrefixes2.some(prefix => formattedNumber.startsWith(prefix))) {
      return 'GLO';
    }

    // Check Airtel prefixes
    if (airtelPrefixes2.some(prefix => formattedNumber.startsWith(prefix))) {
      return 'AIRTEL';
    }

    // Check 9mobile prefixes
    if (nineMobilePrefixes2.some(prefix => formattedNumber.startsWith(prefix))) {
      return '9MOBILE';
    }

    // If the number doesn't match any known prefixes
    return 'Unknown';
  }

  function replaceCountryCode(phoneNumber) {
    return phoneNumber.replace(/^\+234/, '0');
  }

  const handleSelect = (ranges) => {
    setDateRange([ranges.selection]);

    // console.log(ranges.selection.startDate)
    setStartDate(ranges.selection.startDate)
    setEndDate(ranges.selection.endDate)

  };

  useEffect(() => {
    const usrDT = localStorage.getItem('userData')

    if (usrDT) {
      const retrievedData = JSON.parse(usrDT);

      setUserData(retrievedData)

    }

  }, [])


  function formatDate(inputDate) {
    // Parse the input date string
    const parsedDate = new Date(inputDate);

    // Check if the parsing was successful
    if (isNaN(parsedDate.getTime())) {
      return 'Invalid Date';
    }

    // Format the date into the desired string
    const formattedDate = `${parsedDate.getDate()} ${parsedDate.toLocaleString('default', { month: 'short' })} ${parsedDate.getFullYear()}, ${parsedDate.toTimeString().slice(0, 8)}.${parsedDate.getMilliseconds()}`;

    return formattedDate;
  }

  // Function to generate report
  const handleGenerateReport = async () => {
    setOpenDatePick(false)
    setLoading(true);

    // console.log(loading)

    try {
      // Set start time to 00:00:00.000 for startDate
      startDate.setHours(0, 0, 0, 0);
      // Set end time to 23:59:59.000 for endDate
      endDate.setHours(23, 59, 59, 999);

      // console.log(UserToken)

      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", `Bearer ${UserToken}`);

      const msg_id_array = msg_ids.split(',').map(id => id.trim());

      const raw = JSON.stringify({
        msg_id: msg_id_array
      });

      // console.log({startDate}, {endDate})

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(`${url}/api/sms/query/int_msg_id`, requestOptions)
        .then(response => response.json())
        .then(result => {

          if (result.status === 'bad') {
            navigate("/SignIn");
            setLoading(false);

            return;
          }

          setData(result.data);
          setLoading(false);
          console.log(result)


        })
        .catch(error => {
          setLoading(false);

          console.log('error', error)
        });

      setCurrentPage(1);
    } catch (error) {
      console.error("Error generating report:", error);
      setLoading(false);

    } finally {
      // setLoading(false);
    }
  };


  // Function to handle page change
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };


  function getBackgroundColor(phoneNumber) {
    switch (getPhoneNumberCategory(phoneNumber)) {
      case 'MTN NG':
        return '#F6C504';
      case 'GLO':
        return '#248439';
      case 'AIRTEL':
        return '#ED0A16';
      case '9MOBILE':
        return '#036B50';
      default:
        return 'white';
    }
  }

  function formatDate2(inputDate) {
    const year = inputDate.slice(0, 4);
    const month = inputDate.slice(4, 6);
    const day = inputDate.slice(6, 8);
    const hour = inputDate.slice(8, 10);
    const minute = inputDate.slice(10, 12);

    const formattedDate = `${year}-${month}-${day} ${hour}:${minute}`;

    return formattedDate;
  }

  return (
    <div className="report-container">
      {loading && 
       <div className="fixed z-50 w-full h-full top-0 left-0 flex justify-center items-center">
       <div className="flex flex-row ">
         <Spinner />
         {/* <h1 className="relative text-[#196b49] text-lg mt-40">Processing...</h1> */}

       </div>

     </div>
     }

      <div className='head-line flex'>
        <p className='head-1'>Query Report</p>
        <p className='head-2'>Admin</p>
        <p className='head-2'>{'>'}</p>

        <p className='head-2'>Query</p>
        <p className='head-2'>{'>'}</p>

        <p className='head-2'> Int Msg Id</p>

      </div>
      <div className="report-container-inner">
        <p style={{ fontSize: '1.1rem', fontWeight: 600, color: 'var(--primary-grey)' }}>Query Report</p>
        <p style={{ fontSize: '0.8rem', color: 'var(--primary-grey)', marginTop: 10, marginBottom: 10 }}>Input msg_id(s), Can be comma delimited</p>
        <div className="date-section mb-6">
          <div className="picker-cont">
            <div className="picker">
            <StyledInput
               label={'msg_id'}
               handleChange={(e)=> setMsg_ids(e.target.value)}
               />

            </div>
          </div>

          <PrimaryButton text={'Submit'} handleClick={handleGenerateReport} color={'var(--primary-color)'} icon={'ChevronRight'}/>
        </div>

        {data.length > 0 &&
          <>
            
            <div className='table-container'>
              <table id="transact-history">
                <thead>
                  <tr>
                    <th class="selector">
                      <input type="checkbox" id="selectRow1" class="select-row" />
                    </th>
                    <th>Recipient</th>
                    <th>MNO</th>
                    <th>Message</th>
                    <th>Status</th>
                    <th>Route</th>
                    <th>DLR code</th>
                    <th>Submitted</th>
                    <th>Message ID</th>
                  </tr>
                </thead>
                <tbody>
                  {data && data.length > 0 && data.slice().reverse().map((item, index) => (
                    <tr key={index}>
                      <td class="selector">
                        <input type="checkbox" id="selectRow1" class="select-row" />
                      </td>
                      <td>
                        <p className="sid w-40" style={{ fontWeight: 600 }}>{item.data.recipient}</p>
                      </td>
                      <td>
                        <p className="mno" style={{ backgroundColor: getBackgroundColor(replaceCountryCode(item.data.recipient)) }}>
                          {getPhoneNumberCategory(replaceCountryCode(item.data.recipient))}
                          </p>
                      </td>
                      <td>
                        <p className="sid">{item.data.sender_id}</p>
                        <p className="msg text-ellipsis overflow-hidden">{item.data.msg}</p>
                      </td>
                      <td>
                        <p className="sid">{item.data.status === 'DELIVRD' ? 'DELIVERED' : item.data.status === 'UNDELIV' ? 'FAILED' : item.data.status === 'FAILED' ? 'FAILED' : item.data.status}</p>
                        {/* <p className="msg">Message {item.status  === 'DELIVRD'?  <p className="capitalize m-0 p-0">Delivered</p>: item.status === ""? <span></span>: <span></span>}</p> */}
                      </td>
                      <td>{item.data.route}</td>
                      <td>err:{item.data.dlr_code}</td>
                      <td>{item.data.created}</td>
                      {/* <td>{item.dlr ? formatDate2(item.dlr) : <span style={{color: 'orange'}}>SUBMITTED</span>}</td> */}
                      <td>{item.data.msg_id}</td>

                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </>
        }


      </div>

    </div>
  );
};

export default IntMsgId;