import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { formatDate, url } from '../../../utils/utils';
import * as Icon from 'react-feather';
import './styles.scss'
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import PrimaryButton from '../../../Components/PrimaryButton';
import StyledInput from '../../../Components/StyledInput';

const ApiKeys = () => {
    const navigate = useNavigate()
    const [queryLimit, setqueryLimit] = useState(25);
    const [queryPages, setqueryPages] = useState(1);
    const [queryPagesCount, setqueryPagesCount] = useState(0);
    const [subsList, setSubs] = useState([]);
    const [actionBtn, setActionBtn] = useState(false);
    const [actionBtnId, setActionBtnId] = useState(null)
    const [modal, setModal] = useState(false)
    const [isEdit, setIsEdit] = useState(false)
    const [editData, setEditData] = useState({})
    const [loading, setLoading] = useState(false)
    const UserToken = localStorage.getItem("token");
    const [userData, setUserData] = useState({})
    const [route, setRoute] = useState('MKT')
    const [formData, setFormData] = useState({
        callback_url: "",
        name: "",
    })

    const fetchtransactionHistory = (uid) => {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", `Bearer ${UserToken}`);

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };


        fetch(`${url}/api/users/settings/apikeys?uid=${uid}`, requestOptions)
            .then(response => response.json())
            .then(result => {

                if (result.status === 'bad') {
                    navigate("/SignIn");
                    return;
                }
                setSubs(result.data)

            })
            .catch(error => console.log('error', error));
    }


    useEffect(() => {
        const usrDT = localStorage.getItem('userData')
        if (usrDT) {
            const retrievedData = JSON.parse(usrDT);

            setUserData(retrievedData)
            fetchtransactionHistory(retrievedData.uid)
            //   console.log(retrievedData)

        }

    }, [])

    const handleChange = (event) => {
        const val = event.target.value
        setRoute(val);

    };

    const downloadCsv = () => {
        // Get the table element
        const table = document.getElementById('transact-history');

        // Create an array to store rows
        const rows = [];

        // Iterate over each row in the table
        for (let i = 0; i < table.rows.length; i++) {
            const row = [];
            // Iterate over each cell in the row
            for (let j = 0; j < table.rows[i].cells.length; j++) {
                row.push(table.rows[i].cells[j].innerText);
            }
            // Join the row elements with a comma to create a CSV row
            rows.push(row.join(','));
        }

        // Join the rows with a newline character to create the CSV content
        const csvContent = rows.join('\n');

        // Create a Blob containing the CSV content
        const blob = new Blob([csvContent], { type: 'text/csv' });

        // Create a link element
        const link = document.createElement('a');

        // Set the link's attributes
        link.href = window.URL.createObjectURL(blob);
        link.download = 'smarthive_transcation_history.csv';

        // Append the link to the document and trigger the click event
        document.body.appendChild(link);
        link.click();

        // Remove the link from the document
        document.body.removeChild(link);
    };

    const downloadExcel = () => {
        // Get the table element
        const table = document.getElementById('transact-history');

        // Create a worksheet
        const ws = XLSX.utils.table_to_sheet(table);

        // Create a workbook
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

        // Create a Blob containing the Excel file
        const blob = XLSX.write(wb, { bookType: 'xlsx', type: 'blob' });

        // Save the Blob as a file
        saveAs(blob, 'smarthive_transcation_history.xlsx');
    };

    const createKey = (e) => {
        setLoading(true)
        e.preventDefault();
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", `Bearer ${UserToken}`);

        var raw = JSON.stringify({
            uid: userData.uid,
            callback_url: formData.callback_url,
            keyname: formData.keyname,
            route: route
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        console.log(formData)

       if(isEdit){
        fetch(`${url}/api/users/updateApiKey?_id=${editData._id}`, requestOptions)
        .then(response => response.json())
        .then(result => {
            setLoading(false)
            formData.callback_url = ""
            formData.keyname = ""
            setModal(false)
            fetchtransactionHistory(userData.uid)
        })
        .catch(error => {
            console.log('error', error)
            setLoading(false)
        });

        return
       }

       fetch(`${url}/api/users/createApiKey?uid=${userData.uid}`, requestOptions)
       .then(response => response.json())
       .then(result => {
           setLoading(false)
           formData.callback_url = ""
           formData.keyname = ""
           setModal(false)
           fetchtransactionHistory(userData.uid)
       })
       .catch(error => {
           console.log('error', error)
           setLoading(false)
       });
    }

    const handleEditChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    return (
        <>
            {modal &&
                <div className='fixed w-screen h-screen z-[20]'>
                    <div className='backdrop-blur fixed w-full h-full bg-black/20' />
                    <div className=' w-full h-full flex relative flex-col items-center justify-center'>
                        <div className='bg-white p-6 w-96 relative'>
                            <p className='font-bold'>Add New Key</p>
                            <div className='absolute top-0 right-0 m-6 bg-[var(--primary-border-grey)] rounded-full p-1' onClick={() => {
                                setModal(false)
                                setFormData({})
                                setRoute("MKT")
                                }}>
                                <Icon.X size={15} color='var(--primary-grey)' />
                            </div>
                            <form onSubmit={createKey}>
                                <StyledInput
                                    label={'Name'}
                                    value={formData.keyname}
                                    name="keyname"
                                    handleChange={handleEditChange}
                                    required />

                                <StyledInput
                                    label={'Callback URL'}
                                    value={formData.callback_url}
                                    name="callback_url"
                                    handleChange={handleEditChange} />

                                <p className='text-[var(--primary-grey)]'>Route</p>
                                <select className='w-full border rounded-lg mt-2 p-2 text-[var(--primary-grey)]' id="yourSelector" value={route} onChange={handleChange}>
                                    {/* <option value="">Select...</option> */}
                                    <option value="MKT">Marketing</option>
                                    <option value="TRX">Transaction</option>
                                    <option value="INTL">International</option>
                                </select>

                                <div className='mt-6'>
                                    <PrimaryButton text={isEdit? 'Update Key': 'Generate Key'} color={'var(--primary-color)'} primary type="submit" loading={loading} />

                                </div>
                            </form>

                        </div>
                    </div>
                </div>
            }
            <div className='topup-main-container'>
                <div className='head-line flex'>
                    <p className='head-1'>Manage API Keys</p>
                    <p className='head-2'>Dashboard</p>
                    <p className='head-2'>{'>'}</p>

                    <p className='head-2'>Settings</p>
                    <p className='head-2'>{'>'}</p>

                    <p className='head-2'>API Keys</p>

                </div>

                <div className='topup-content-container history'>

                    <div className='flex justify-between'>
                        <div className='flex'>
                            <p>Download</p>

                            <p className='each_option' onClick={() => downloadCsv()}>CSV</p>
                            <p className='each_option' onClick={() => downloadExcel()}>Excel</p>
                            {/* <p className='each_option' onClick={()=>  window.print()}>Print</p> */}

                        </div>

                        <PrimaryButton text={'New Key'}
                            icon={'Plus'}
                            color={'var(--primary-color)'}
                            primary
                            handleClick={() => setModal(true)}
                        />


                    </div>
                    <div className='comp-container-api'>
                        <div className='comp-container-inner'>
                            <div className='table-container'>
                                <table id="transact-history">
                                    <thead>
                                        <tr>
                                            <th class="selector">
                                                <input type="checkbox" id="selectRow1" class="select-row" />
                                            </th>
                                            <th>S/N</th>
                                            <th>Name</th>
                                            <th>Key</th>
                                            <th>Status</th>
                                            <th>Callback URL</th>
                                            <th>Route</th>
                                            <th>Action</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        {subsList && subsList.length > 0 && subsList.slice().reverse().map((item, index) => (
                                            <tr key={index}>
                                                <td class="selector">
                                                    <input type="checkbox" id="selectRow1" class="select-row" />
                                                </td>
                                                <td>
                                                    {index + 1}
                                                </td>
                                                <td>{item.keyname}</td>
                                                <td>{item.apiKey}</td>
                                                <td><p className={item.status === 'ACTIVE' ? 'bg-[green] text-white' : 'bg-[orange] text-white'}>{item.status}</p></td>
                                                <td>{item.callback_url}</td>
                                                <td>{item.route}</td>

                                                <td><PrimaryButton
                                                    text={'Edit'}
                                                    color={'var(--primary-color)'}
                                                    handleClick={() => {
                                                        setIsEdit(true)
                                                        setModal(true)
                                                        setEditData(item)
                                                        formData.keyname = item.keyname
                                                        formData.callback_url = item.callback_url
                                                        setRoute(item.route)
                                                    }} /></td>

                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>

                            <div className='flex bottom-qry-container'>

                                <div className='flex'>
                                    <div className='selector-container'>
                                        <select id="yourSelector" value={queryLimit} onChange={handleChange}>
                                            {/* <option value="">Select...</option> */}
                                            <option value="25">25</option>
                                            <option value="50">50</option>
                                            <option value="100">100</option>
                                            <option value="200">200</option>
                                            {/* Add more options as needed */}
                                        </select>

                                    </div>
                                    {/* <span> {queryPages} - {queryPagesCount} of {queryPagesCount}</span> */}

                                </div>

                                <div className='page-nav-container'>
                                    <span>{'<'}</span>
                                    <span className='count'>{queryPages}</span>
                                    <span>{'>'}</span>

                                </div>

                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}

export default ApiKeys