import React, { useEffect } from "react";

// import { checkUserSession } from "./redux/User/user.actions";
// import { useDispatch } from "react-redux";
// import logo from "./logo.svg";
import "./App.scss";
// import { Route, Router, Routes } from 'react-router-dom';
import "./App.scss";
import { Routes, Route, useNavigate, Navigate } from "react-router-dom";

//layouts
import NoNavLayout from "./Layouts/NoNavLayout";

//pages
import SignupForm from "./Pages/SignUp";
import SignIn from "./Pages/SignIn";
import ForgetPassword from "./Pages/ForgetPassword";
import Dashboard from "./Pages/Dashboard";
import DashBoardLayout from "./Layouts/DashBoardLayout";
import ReportPage from "./Pages/SMS/ReportPage";
import SENT from "./Pages/SMS/SENT";
import SEND from "./Pages/SMS/SEND";
import contruct from "./assets/builder.png";
import Topup from "./Pages/Transactions/Topup";
import TransactionHistory from "./Pages/Transactions/History";
import ApiKeys from "./Pages/Settings/ApiKeys";
import SenderID from "./Pages/Settings/SenderID";
import Profile from "./Pages/Settings/Profile";

// admin
import AdminSenderID from "./Pages/Admin/SIDs";
import SMSReport from "./Pages/Admin/SMSReport";
import ContactGroup from "./Pages/Settings/ContactGroup";
import Users from "./Pages/Admin/Users";
import IntMsgId from "./Pages/Admin/Query/IntMsgId";

function App() {
  return (
    <React.Fragment>
      <Routes>
        <Route path="/" element={<Navigate to="/dashboard" />} />

        <Route
          path="/SignUp"
          element={
            <NoNavLayout>
              <SignupForm />
            </NoNavLayout>
          }
        />

        <Route
          path="/SignIn"
          element={
            <NoNavLayout>
              <SignIn />
            </NoNavLayout>
          }
        />

        <Route
          path="/ForgetPassword"
          element={
            <NoNavLayout>
              <ForgetPassword />
            </NoNavLayout>
          }
        />

        <Route
          path="/dashboard"
          element={
            <DashBoardLayout>
              <Dashboard />
            </DashBoardLayout>
          }
        />

        <Route
          path="/sms/report"
          element={
            <DashBoardLayout>
              <ReportPage />
            </DashBoardLayout>
          }
        />

        <Route
          path="/sms/send"
          element={
            <DashBoardLayout>
              <SEND />

              {/*              <div style={{ height: '80vh' }}>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                  <p style={{ color: 'var(--primary-color)', fontWeight: 600 }}>This Page is under construction. Please come back later</p>

                  <p style={{ padding: 0, margin: 0, fontSize: 14 }}>We're working hard to serve you better</p>

                  <img style={{ width: 150, marginTop: 20 }} src={contruct} alt='' />
                </div>
          </div>*/}
            </DashBoardLayout>
          }
        />

        <Route
          path="/sms/sent/:id"
          element={
            <DashBoardLayout>
              <SENT />
            </DashBoardLayout>
          }
        />

        <Route
          path="/transaction/topup"
          element={
            <DashBoardLayout>
              <Topup />
            </DashBoardLayout>
          }
        />

        <Route
          path="/transaction/history"
          element={
            <DashBoardLayout>
              <TransactionHistory />
            </DashBoardLayout>
          }
        />

        <Route
          path="/settings/apikeys"
          element={
            <DashBoardLayout>
              <ApiKeys />
            </DashBoardLayout>
          }
        />

        <Route
          path="/settings/sid"
          element={
            <DashBoardLayout>
              <SenderID />
            </DashBoardLayout>
          }
        />

        <Route
          path="/settings/contact-group"
          element={
            <DashBoardLayout>
              <ContactGroup />
            </DashBoardLayout>
          }
        />

        <Route
          path="/settings/profile"
          element={
            <DashBoardLayout>
              <Profile />
            </DashBoardLayout>
          }
        />

        {/* amdin */}

        <Route
          path="/admin/senderid-manager"
          element={
            <DashBoardLayout>
              <AdminSenderID />
            </DashBoardLayout>
          }
        />

        <Route
          path="/admin/sms-reports"
          element={
            <DashBoardLayout>
              <SMSReport />
            </DashBoardLayout>
          }
        />

        <Route
          path="/admin/users-manager"
          element={
            <DashBoardLayout>
              <Users />
            </DashBoardLayout>
          }
        />

<Route
          path="/admin/query/int-msg-id"
          element={
            <DashBoardLayout>
              <IntMsgId />
            </DashBoardLayout>
          }
        />
      </Routes>
    </React.Fragment>
  );
}

export default App;
