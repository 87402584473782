import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { formatDate, url } from '../../../utils/utils';
import * as Icon from 'react-feather';
import './styles.scss'
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

const TransactionHistory = () => {
    const navigate = useNavigate()
    const [queryLimit, setqueryLimit] = useState(25);
    const [queryPages, setqueryPages] = useState(1);
    const [queryPagesCount, setqueryPagesCount] = useState(0);
    const [subsList, setSubs] = useState([]);
    const [actionBtn, setActionBtn] = useState(false);
    const [actionBtnId, setActionBtnId] = useState(null)
    const [isAlert, setIsAlert] = useState(false)
    const UserToken = localStorage.getItem("token");
    const [userData, setUserData] = useState({})

    const fetchtransactionHistory = (uid) => {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", `Bearer ${UserToken}`);

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };


        fetch(`${url}/api/users/transaction-history?uid=${uid}`, requestOptions)
            .then(response => response.json())
            .then(result => {

                if (result.status === 'bad') {
                    navigate("/SignIn");
                    return;
                }
                setSubs(result.data)

            })
            .catch(error => console.log('error', error));
    }


    useEffect(() => {
        const usrDT = localStorage.getItem('userData')

        if (usrDT) {
            const retrievedData = JSON.parse(usrDT);

            setUserData(retrievedData)
            fetchtransactionHistory(retrievedData.uid)
            //   console.log(retrievedData)

        }

    }, [])

    const handleChange = (event) => {
        const val = event.target.value
        setqueryLimit(val);
        fetchtransactionHistory(userData.uid)

    };

    const downloadCsv = () => {
        // Get the table element
        const table = document.getElementById('transact-history');

        // Create an array to store rows
        const rows = [];

        // Iterate over each row in the table
        for (let i = 0; i < table.rows.length; i++) {
            const row = [];
            // Iterate over each cell in the row
            for (let j = 0; j < table.rows[i].cells.length; j++) {
                row.push(table.rows[i].cells[j].innerText);
            }
            // Join the row elements with a comma to create a CSV row
            rows.push(row.join(','));
        }

        // Join the rows with a newline character to create the CSV content
        const csvContent = rows.join('\n');

        // Create a Blob containing the CSV content
        const blob = new Blob([csvContent], { type: 'text/csv' });

        // Create a link element
        const link = document.createElement('a');

        // Set the link's attributes
        link.href = window.URL.createObjectURL(blob);
        link.download = 'smarthive_transcation_history.csv';

        // Append the link to the document and trigger the click event
        document.body.appendChild(link);
        link.click();

        // Remove the link from the document
        document.body.removeChild(link);
    };

    const downloadExcel = () => {
        // Get the table element
        const table = document.getElementById('transact-history');

        // Create a worksheet
        const ws = XLSX.utils.table_to_sheet(table);

        // Create a workbook
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

        // Create a Blob containing the Excel file
        const blob = XLSX.write(wb, { bookType: 'xlsx', type: 'blob' });

        // Save the Blob as a file
        saveAs(blob, 'smarthive_transcation_history.xlsx');
    };

    return (
        <div className='topup-main-container'>
            <div className='head-line flex'>
                <p className='head-1'>Transaction History</p>
                <p className='head-2'>Dashboard</p>
                <p className='head-2'>{'>'}</p>

                <p className='head-2'>Transaction</p>
                <p className='head-2'>{'>'}</p>

                <p className='head-2'>History</p>

            </div>

            <div className='topup-content-container history'>

                <div className='flex'>
                    <p>Download</p>

                    <p className='each_option' onClick={() => downloadCsv()}>CSV</p>
                    <p className='each_option' onClick={() => downloadExcel()}>Excel</p>
                    {/* <p className='each_option' onClick={()=>  window.print()}>Print</p> */}

                </div>
                <div className='comp-container'>
                    <div className='comp-container-inner'>
                        <div className='table-container'>
                            <table id="transact-history">
                                <thead>
                                    <tr>
                                        <th class="selector">
                                            <input type="checkbox" id="selectRow1" class="select-row" />
                                        </th>
                                        <th>Invoice No</th>
                                        <th>Amount</th>
                                        <th>VAT{'(7.5%)'}</th>
                                        <th>Total</th>
                                        <th>Status</th>
                                        <th>Timestamp</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {subsList && subsList.length > 0 && subsList.slice().reverse().map((item, index) => (
                                        <tr key={index}>
                                            <td class="selector">
                                                <input type="checkbox" id="selectRow1" class="select-row" />
                                            </td>
                                            <td style={{ color: 'var(--primary-color)', fontWeight: 600 }}>IN{item.invoiceNo}</td>
                                            <td>&#8358;{item.def_amount}</td>
                                            <td>&#8358;{(7.5 / 100) * parseInt(item.def_amount)}</td>
                                            <td>&#8358;{item.def_amount + ((7.5 / 100) * parseInt(item.def_amount))}</td>
                                            <td>{
                                                item.status === 'success' ?
                                                    <p style={{ color: 'green', fontWeight: 600 }}>{item.status}</p> : <p style={{ color: 'red', fontWeight: 600 }}>Unpaid</p>
                                            }</td>
                                            <td>{formatDate(item.created_at)}</td>

                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>

                        <div className='flex bottom-qry-container'>

                            <div className='flex'>
                                <div className='selector-container'>
                                    <select id="yourSelector" value={queryLimit} onChange={handleChange}>
                                        {/* <option value="">Select...</option> */}
                                        <option value="25">25</option>
                                        <option value="50">50</option>
                                        <option value="100">100</option>
                                        <option value="200">200</option>
                                        {/* Add more options as needed */}
                                    </select>

                                </div>
                                {/* <span> {queryPages} - {queryPagesCount} of {queryPagesCount}</span> */}

                            </div>

                            <div className='page-nav-container'>
                                <span>{'<'}</span>
                                <span className='count'>{queryPages}</span>
                                <span>{'>'}</span>

                            </div>

                        </div>
                    </div>

                </div>
            </div>
        </div>

    )
}

export default TransactionHistory