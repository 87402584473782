import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
// import { PersistGate } from 'redux-persist/integration/react';
// import { store, persistor } from './redux/createStore';
import App from './App';
import './index.scss';

// import {registerServiceWorker, requestPermission } from './firebase/utils';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    {/* <Provider store={store}> */}
      <BrowserRouter initialEntries={['/dashboard']}>
        {/* <PersistGate persistor={persistor}> */}
          <App />
        {/* </PersistGate> */}
      </BrowserRouter>
    {/* </Provider> */}
  </React.StrictMode>,
);

// registerServiceWorker();
// requestPermission()
