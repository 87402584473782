/* eslint-disable no-mixed-operators */
import React, { useEffect, useState } from 'react'
import './styles.scss';
import img from '../../../assets/companylogo.png'
import * as Icon from 'react-feather';
import { Link, useNavigate } from 'react-router-dom';

const LeftNav = ({ onNavClicked }) => {
  const navigate = useNavigate()
  const [userData, setUserData] = useState({})
  const [dropdown, setDropdow] = useState(false)
  const [dropdownId, setDropdowId] = useState(null)

  useEffect(() => {
    const usrDT = localStorage.getItem('userData')

    if (usrDT) {
      const retrievedData = JSON.parse(usrDT);

      setUserData(retrievedData)

      // console.log(retrievedData.role)
    }
  }, [])

  const handledropdown = (id) => {
    setDropdowId(id)

    if (dropdown && dropdownId === id) {
      setDropdow(false)
      setDropdowId(null)
    } else {
      setDropdow(true)
      setDropdowId(id)

    }

  }

  const handleItemClick = () => {
    // Call the provided callback when an item in LeftNav is clicked
    if (onNavClicked) {
      onNavClicked();

    }

  };


  const handleSignout = () => {
    localStorage.setItem('userData', '')
    localStorage.setItem('token', '')
    localStorage.setItem('authTimein', '')

    navigate('/SignIn');
  }

  return (
    <div className='left-nav'>
      <div className='left-nav-container'>
        <div className='left-nav-container-inner'>
          <div className='logo flex'>
            <img src={img} alt='smarthive sms | smarthivetech solution | bulk sms Nigeria' />
            <span>SmartHive SMS</span>
          </div>

          <div className='nav-container'>
            <div className='eachNav-category-container'>

              {userData.role !== 'USER' &&
                (
                  <div>
                    <span className='cat-head'>ADMIN</span>
                    <div className='eachNav-category-dropdown'>
                      <div className='eachNav-category-dropdown-item' onClick={() => handledropdown(0)}>
                        <div className='flex'>
                          <Icon.User />

                          <p>Admin</p>
                        </div>
                        <div className={dropdown && dropdownId === 0 ? 'dropdown-icn active' : 'dropdown-icn'}>
                          <Icon.ChevronUp color='var(--primary-grey)' size={20} />
                        </div>
                      </div>

                      <div className={dropdown && dropdownId === 0 ? 'dropdown-container active' : 'dropdown-container'}>
                        {userData.role === 'SADMIN' &&
                          <div className='each-dropdown-item'>
                          <Link to='/admin/users-manager' onClick={() => handleItemClick()}>
                            <p>Users</p>
                          </Link>
                        </div>
                        }
                        <div className='each-dropdown-item'>
                          <Link to='/admin/apikeys-manager' onClick={() => handleItemClick()}>
                            <p>API Keys</p>

                          </Link>

                        </div>
                        {userData.role === 'SADMIN' && (
                          <div className='each-dropdown-item'>
                            <Link to='/admin/transactions-manager' onClick={() => handleItemClick()}>
                              <p>Transactions</p>

                            </Link>

                          </div>
                        )}
                        <div className='each-dropdown-item'>
                          <Link to='/admin/sms-reports' onClick={() => handleItemClick()}>
                            <p>Report</p>

                          </Link>

                        </div>
                        <div className='each-dropdown-item'>
                          <Link to='/admin/senderid-manager' onClick={() => handleItemClick()}>
                            <p>Sender ID</p>

                          </Link>

                        </div>
                      </div>

                    </div>
                    <span className='cat-head'>QUERY</span>
                    <div className='eachNav-category-dropdown'>
                      <div className='eachNav-category-dropdown-item' onClick={() => handledropdown(4)}>
                        <div className='flex'>
                          <Icon.User />

                          <p>Query</p>
                        </div>
                        <div className={dropdown && dropdownId === 4 ? 'dropdown-icn active' : 'dropdown-icn'}>
                          <Icon.ChevronUp color='var(--primary-grey)' size={24} />
                        </div>
                      </div>

                      <div className={dropdown && dropdownId === 4 ? 'dropdown-container active' : 'dropdown-container'}>
                        <div className='each-dropdown-item'>
                          <Link to='/admin/query/int-msg-id' onClick={() => handleItemClick()}>
                            <p>Int Msg Id</p>
                          </Link>
                        </div>
                        <div className='each-dropdown-item'>
                          <Link to='/admin/query/exxt' onClick={() => handleItemClick()}>
                            <p>Ext Msg Id</p>

                          </Link>

                        </div>
                        <div className='each-dropdown-item'>
                          <Link to='/admin/apikeys-manager' onClick={() => handleItemClick()}>
                            <p>MSISDN</p>

                          </Link>

                        </div>
                        
                      </div>

                    </div>
                  </div>
                )}
              <span className='cat-head'>MAIN</span>
              <Link to='/dashboard' onClick={() => handleItemClick()}>
                <div className='eachNav-category'>
                  <svg width="20" height="20" viewBox="0 0 20 20" stroke="none" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_1173_12128)">
                      <path d="M13.334 4.16666C13.334 3.39009 13.334 3.0018 13.4609 2.69552C13.63 2.28714 13.9545 1.96268 14.3628 1.79352C14.6691 1.66666 15.0574 1.66666 15.834 1.66666C16.6106 1.66666 16.9988 1.66666 17.3051 1.79352C17.7135 1.96268 18.038 2.28714 18.2071 2.69552C18.334 3.0018 18.334 3.39009 18.334 4.16666V7.49999C18.334 8.27656 18.334 8.66484 18.2071 8.97113C18.038 9.37951 17.7135 9.70397 17.3051 9.87312C16.9988 9.99999 16.6106 9.99999 15.834 9.99999C15.0574 9.99999 14.6691 9.99999 14.3628 9.87312C13.9545 9.70397 13.63 9.37951 13.4609 8.97113C13.334 8.66484 13.334 8.27656 13.334 7.49999V4.16666Z" stroke="none" stroke-width="1.5" />
                      <path d="M13.334 15.8333C13.334 15.0567 13.334 14.6685 13.4609 14.3622C13.63 13.9538 13.9545 13.6293 14.3628 13.4602C14.6691 13.3333 15.0574 13.3333 15.834 13.3333C16.6106 13.3333 16.9988 13.3333 17.3051 13.4602C17.7135 13.6293 18.038 13.9538 18.2071 14.3622C18.334 14.6685 18.334 15.0567 18.334 15.8333C18.334 16.6099 18.334 16.9982 18.2071 17.3045C18.038 17.7128 17.7135 18.0373 17.3051 18.2064C16.9988 18.3333 16.6106 18.3333 15.834 18.3333C15.0574 18.3333 14.6691 18.3333 14.3628 18.2064C13.9545 18.0373 13.63 17.7128 13.4609 17.3045C13.334 16.9982 13.334 16.6099 13.334 15.8333Z" stroke="none" stroke-width="1.5" />
                      <path d="M1.66699 13.3333C1.66699 11.762 1.66699 10.9763 2.15515 10.4882C2.6433 10 3.42898 10 5.00033 10H6.66699C8.23834 10 9.02401 10 9.51217 10.4882C10.0003 10.9763 10.0003 11.762 10.0003 13.3333V15C10.0003 16.5713 10.0003 17.357 9.51217 17.8452C9.02401 18.3333 8.23834 18.3333 6.66699 18.3333H5.00033C3.42898 18.3333 2.6433 18.3333 2.15515 17.8452C1.66699 17.357 1.66699 16.5713 1.66699 15V13.3333Z" stroke="none" stroke-width="1.5" />
                      <path d="M1.66699 4.16666C1.66699 3.39009 1.66699 3.0018 1.79386 2.69552C1.96302 2.28714 2.28747 1.96268 2.69585 1.79352C3.00214 1.66666 3.39042 1.66666 4.16699 1.66666H7.50033C8.27689 1.66666 8.66518 1.66666 8.97146 1.79352C9.37984 1.96268 9.7043 2.28714 9.87346 2.69552C10.0003 3.0018 10.0003 3.39009 10.0003 4.16666C10.0003 4.94323 10.0003 5.33151 9.87346 5.6378C9.7043 6.04618 9.37984 6.37063 8.97146 6.53979C8.66518 6.66666 8.27689 6.66666 7.50033 6.66666H4.16699C3.39042 6.66666 3.00214 6.66666 2.69585 6.53979C2.28747 6.37063 1.96302 6.04618 1.79386 5.6378C1.66699 5.33151 1.66699 4.94323 1.66699 4.16666Z" stroke="none" stroke-width="1.5" />
                    </g>
                    <defs>
                      <clipPath id="clip0_1173_12128">
                        <rect width="20" height="20" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                  <p>Dashboard</p>
                </div>
              </Link>

              <div className='eachNav-category-dropdown'>
                <div className='eachNav-category-dropdown-item' onClick={() => handledropdown(1)}>
                  <div className='flex'>
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clip-path="url(#clip0_1173_12137)">
                        <path d="M18.3337 9.63892C18.3337 14.0415 14.6022 17.6112 10.0003 17.6112C9.45923 17.6119 8.91969 17.5619 8.38817 17.462C8.0056 17.3902 7.81431 17.3543 7.68077 17.3747C7.54722 17.3951 7.35797 17.4957 6.97948 17.697C5.90877 18.2664 4.66029 18.4675 3.45958 18.2441C3.91594 17.6828 4.22761 17.0093 4.36514 16.2873C4.44847 15.8457 4.24199 15.4166 3.93273 15.1026C2.5281 13.6763 1.66699 11.7542 1.66699 9.63892C1.66699 5.2363 5.39847 1.66666 10.0003 1.66666C14.6022 1.66666 18.3337 5.2363 18.3337 9.63892Z" stroke="#454A5F" stroke-width="1.5" stroke-linejoin="round" />
                        <path d="M9.99659 10H10.0041M13.3262 10H13.3337M6.66699 10H6.67447" stroke="#454A5F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                      </g>
                      <defs>
                        <clipPath id="clip0_1173_12137">
                          <rect width="20" height="20" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>

                    <p>SMS</p>
                  </div>
                  <div className={dropdown && dropdownId === 1 ? 'dropdown-icn active' : 'dropdown-icn'}>
                    <Icon.ChevronUp color='var(--primary-grey)' size={20} />
                  </div>
                </div>

                <div className={dropdown && dropdownId === 1 ? 'dropdown-container active' : 'dropdown-container'}>
                  <div className='each-dropdown-item'>
                    <Link to='/sms/send' onClick={() => handleItemClick()}>
                      <p>Send SMS</p>
                    </Link>
                  </div>
                  <div className='each-dropdown-item'>
                    <Link to='/sms/report' onClick={() => handleItemClick()}>
                      <p>Report</p>

                    </Link>

                  </div>
                </div>

              </div>

              <div className='eachNav-category-dropdown'>
                <div className='eachNav-category-dropdown-item' onClick={() => handledropdown(2)}>
                  <div className='flex'>
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clip-path="url(#clip0_1173_12168)">
                        <path d="M16.68 1.66669C15.7522 1.66669 15 3.90526 15 6.66669H16.68C17.4897 6.66669 17.8945 6.66669 18.1451 6.38714C18.3957 6.1076 18.3521 5.73946 18.2648 5.00319C18.0345 3.05955 17.4119 1.66669 16.68 1.66669Z" stroke="#454A5F" stroke-width="1.5" />
                        <path d="M15.0003 6.7119V15.5382C15.0003 16.7979 15.0003 17.4278 14.6154 17.6757C13.9863 18.0809 13.0137 17.2312 12.5246 16.9228C12.1204 16.6679 11.9184 16.5404 11.6941 16.5331C11.4518 16.5251 11.2461 16.6473 10.8094 16.9228L9.21699 17.927C8.78745 18.1979 8.57265 18.3334 8.33366 18.3334C8.09466 18.3334 7.87987 18.1979 7.45033 17.927L5.85793 16.9228C5.45378 16.6679 5.25171 16.5404 5.02744 16.5331C4.78509 16.5251 4.57943 16.6473 4.14272 16.9228C3.65362 17.2312 2.68105 18.0809 2.05195 17.6757C1.66699 17.4278 1.66699 16.7979 1.66699 15.5382V6.7119C1.66699 4.33357 1.66699 3.1444 2.39923 2.40554C3.13146 1.66669 4.30997 1.66669 6.66699 1.66669H16.667" stroke="#454A5F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M8.33366 6.66671C7.41318 6.66671 6.66699 7.22635 6.66699 7.91671C6.66699 8.60706 7.41318 9.16671 8.33366 9.16671C9.25413 9.16671 10.0003 9.72635 10.0003 10.4167C10.0003 11.1071 9.25413 11.6667 8.33366 11.6667M8.33366 6.66671C9.05934 6.66671 9.67669 7.01454 9.90549 7.50004M8.33366 6.66671V5.83337M8.33366 11.6667C7.60798 11.6667 6.99063 11.3189 6.76183 10.8334M8.33366 11.6667V12.5" stroke="#454A5F" stroke-width="1.5" stroke-linecap="round" />
                      </g>
                      <defs>
                        <clipPath id="clip0_1173_12168">
                          <rect width="20" height="20" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                    <p>Transactions</p>
                  </div>
                  <div className={dropdown && dropdownId === 2 ? 'dropdown-icn active' : 'dropdown-icn'}>
                    <Icon.ChevronUp color='var(--primary-grey)' size={20} />
                  </div>
                </div>

                <div className={dropdown && dropdownId === 2 ? 'dropdown-container active' : 'dropdown-container'}>
                  <div className='each-dropdown-item'>
                    <Link to={'/transaction/topup'} onClick={() => handleItemClick()}>
                      <p>Account Top-up</p>
                    </Link>

                  </div>
                  <div className='each-dropdown-item'>
                    <Link to={'/transaction/history'} onClick={() => handleItemClick()}>
                      <p>Payment History</p>

                    </Link>
                  </div>
                </div>

              </div>

              <Link to='https://smarthive-tech-solution.gitbook.io/smarthive-tech-solution-api/' target='_blank' onClick={() => handleItemClick()}>
                <div className='eachNav-category'>
                  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7.50033 5.83333H13.9587C15.7142 5.83333 16.592 5.83333 17.2226 6.25466C17.4956 6.43706 17.7299 6.67143 17.9123 6.94441C18.3337 7.57497 18.3337 8.45276 18.3337 10.2083C18.3337 13.1343 18.3337 14.5973 17.6315 15.6482C17.3275 16.1032 16.9368 16.4938 16.4819 16.7978C15.4309 17.5 13.968 17.5 11.042 17.5H10.0003C6.07195 17.5 4.10777 17.5 2.88738 16.2796C1.66699 15.0592 1.66699 13.095 1.66699 9.16667V6.62023C1.66699 5.1065 1.66699 4.34964 1.98392 3.78172C2.20984 3.37689 2.54388 3.04285 2.94871 2.81693C3.51663 2.5 4.27349 2.5 5.78722 2.5C6.75701 2.5 7.24191 2.5 7.66637 2.65917C8.63551 3.0226 9.03515 3.90298 9.47246 4.7776L10.0003 5.83333" stroke="#454A5F" stroke-width="1.5" stroke-linecap="round" />
                    <path d="M12.917 10L13.6836 10.6607C14.0059 10.9385 14.167 11.0774 14.167 11.25C14.167 11.4226 14.0059 11.5615 13.6836 11.8393L12.917 12.5" stroke="#454A5F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M7.08398 10L6.3174 10.6607C5.99512 10.9385 5.83398 11.0774 5.83398 11.25C5.83398 11.4226 5.99512 11.5615 6.3174 11.8393L7.08398 12.5" stroke="#454A5F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M10.8337 9.16669L9.16699 13.3334" stroke="#454A5F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>

                  <p>Documentation</p>
                </div>
              </Link>
            </div>

            <div className='eachNav-category-container' style={{ marginTop: 8 }}>
              <span className='cat-head'>SETTINGS</span>

              <div className='eachNav-category-dropdown'>

                <div className='eachNav-category-dropdown-item' onClick={() => handledropdown(3)}>
                  <div className='flex'>
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M2.08331 9.99967C2.08331 6.26772 2.08331 4.40175 3.24268 3.24238C4.40205 2.08301 6.26803 2.08301 9.99998 2.08301C13.7319 2.08301 15.5979 2.08301 16.7573 3.24238C17.9166 4.40175 17.9166 6.26772 17.9166 9.99967C17.9166 13.7316 17.9166 15.5976 16.7573 16.757C15.5979 17.9163 13.7319 17.9163 9.99998 17.9163C6.26803 17.9163 4.40205 17.9163 3.24268 16.757C2.08331 15.5976 2.08331 13.7316 2.08331 9.99967Z" stroke="#454A5F" stroke-width="1.5" stroke-linejoin="round" />
                      <path d="M7.08331 8.33301C6.39296 8.33301 5.83331 7.77336 5.83331 7.08301C5.83331 6.39265 6.39296 5.83301 7.08331 5.83301C7.77367 5.83301 8.33331 6.39265 8.33331 7.08301C8.33331 7.77336 7.77367 8.33301 7.08331 8.33301Z" stroke="#454A5F" stroke-width="1.5" />
                      <path d="M12.9166 14.166C13.607 14.166 14.1666 13.6064 14.1666 12.916C14.1666 12.2257 13.607 11.666 12.9166 11.666C12.2263 11.666 11.6666 12.2257 11.6666 12.916C11.6666 13.6064 12.2263 14.166 12.9166 14.166Z" stroke="#454A5F" stroke-width="1.5" />
                      <path d="M8.33329 7.08301L14.1666 7.08301" stroke="#454A5F" stroke-width="1.5" stroke-linecap="round" />
                      <path d="M11.6666 12.916L5.83331 12.916" stroke="#454A5F" stroke-width="1.5" stroke-linecap="round" />
                    </svg>

                    <p>Settings</p>
                  </div>
                  <div className={dropdown && dropdownId === 3 ? 'dropdown-icn active' : 'dropdown-icn'}>
                    <Icon.ChevronUp color='var(--primary-grey)' size={20} />
                  </div>

                </div>

                <div className={dropdown && dropdownId === 3 ? 'dropdown-container active' : 'dropdown-container'}>
                  <div className='each-dropdown-item'>
                    <Link to={'/settings/profile'} onClick={() => handleItemClick()}>
                      <p>Profile</p>
                    </Link>
                  </div>
                  <div className='each-dropdown-item'>
                    <Link to={'/settings/sid'} onClick={() => handleItemClick()}>
                      <p>Sender ID</p>
                    </Link>
                  </div>
                  <div className='each-dropdown-item'>
                    <Link to={'/settings/contact-group'} onClick={() => handleItemClick()}>
                    <p>Contact Group</p>
                    </Link>
                  </div>
                  {/* <div className='each-dropdown-item'>
                    <p>Contact Group</p>
                  </div> */}
                  <div className='each-dropdown-item'>

                    <Link to={'/settings/apikeys'} onClick={() => handleItemClick()}>
                      <p>API Keys</p>
                    </Link>
                  </div>
                  
                </div>
              </div>
            </div>
          </div>

          <div className='bottom' onClick={handleSignout}>
            <div className='bottom-content flex'>
              <span>Log out</span>
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12.5 14.6875C12.4387 16.2308 11.1526 17.5412 9.4297 17.499C9.02887 17.4892 8.53344 17.3495 7.5426 17.07C5.15801 16.3974 3.08796 15.267 2.5913 12.7346C2.5 12.2691 2.5 11.7453 2.5 10.6977L2.5 9.30229C2.5 8.25468 2.5 7.73087 2.5913 7.26538C3.08796 4.73304 5.15801 3.60263 7.5426 2.93002C8.53345 2.65054 9.02887 2.5108 9.4297 2.50099C11.1526 2.45884 12.4387 3.76923 12.5 5.31251" stroke="#DA3721" stroke-width="1.5" stroke-linecap="round" />
                <path d="M17.5 10.0003H8.33331M17.5 10.0003C17.5 9.4168 15.8381 8.3266 15.4166 7.91699M17.5 10.0003C17.5 10.5838 15.8381 11.6741 15.4166 12.0837" stroke="#DA3721" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LeftNav