import React, { useState } from "react";
import { Link } from "react-router-dom";
// import SignIn from "../SignIn";
import "./styles.scss";
import { url } from "../../utils/utils";


const ForgetPassword = () => {
  // State variables to manage form data and errors
  const [formData, setFormData] = useState({
    email: "",
  });

  const [formError, setFormError] = useState("");
  const UserToken = localStorage.getItem("token");

  // Event handler for input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Event handler for form submission
  const handleSubmit = (e) => {
    e.preventDefault();

    // Validate email
    if (formData.email.trim() === "" || !/\S+@\S+\.\S+/.test(formData.email)) {
      setFormError("Please enter a valid email address.");
    } else {
      // If email is valid, proceed with password reset
      // console.log("Reset password for email:", formData.email);

      const myHeaders = new Headers();

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        redirect: "follow"
      };

      fetch(`${url}/api/users/forgotpassword?email=${formData.email}`, requestOptions)
        .then((response) => response.json())
        .then((result) => {
          // setFormError(result);
          // setFormData({ email: "" });
          if (result.error) {
            setFormError(result.message);
          } else{
            setFormError("New password has been sent to your email. You can change your password after you login");

          }

        })
        .catch((error) => {
          setFormError(error.message);
          // console.error(error.message)
        });
    }
  };


  return (
    <div className="forgot-password-page">
      <div className="form-container">
        {/* Form for password reset */}
        <form onSubmit={handleSubmit} className="form">
          <h2 className="heading-tertiary">Forgot Your Password?</h2>
          <p className="reset-instructions">
            Enter your email and we'll send you a link to reset your password.
          </p>

          {/* Input field for email */}
          <div className="input-group">
            <input
              type="email"
              id="email"
              placeholder="Your Email"
              name="email"
              value={formData.email}
              onChange={handleChange}
            />
          </div>

          {/* Display error for invalid email */}
          {formError && <span className="error">{formError}</span>}

          {/* Button */}
          <button type="submit">Reset Password</button>

          {/* Link to sign in if the user remembered their password */}
          <p className="signinLink">
            Remembered your password? <Link to="/SignIn">Sign in</Link>
          </p>
        </form>
      </div>
    </div>
  );
};


export default ForgetPassword;
