import React, { useEffect, useState } from 'react';
import './styles.scss';
import person from '../../../assets/image-19.png'
import * as Icon from 'react-feather';
import LeftNav from '../LeftNav';


const TopNav = () => {
  const [userData, setUserData] = useState({})
  const [navActive, setNavActive] = useState(false)

  useEffect(() => {
    const usrDT = localStorage.getItem('userData')

    if (usrDT) {
      const retrievedData = JSON.parse(usrDT);

      setUserData(retrievedData)
      
    }

  }, [])

  const navTrig = () => {
    if (navActive) setNavActive(false)
    else setNavActive(true)
  }

  return (
    <div className="top-navbar">
      <div className="top-navbar-container">

        <div className="top-navbar-container-inner flex">
          <div className={navActive ? 'left-Nav active' : 'left-Nav'}>
            <LeftNav onNavClicked={()=> navTrig()}/>
            <div className='blackBg'  onClick={() => setNavActive(false)}/>
          </div>

          <div className='nav-btn' onClick={() => navTrig()}>
            <Icon.Menu color='var(--primary-grey)' size={20} />
          </div>

          <div className='more-props'>

            <div className='profile'>
              {userData.username ? (
                <p>{userData.username.slice(0, 1)}</p>
              ) : (
                <img src={person} alt='' />

              )}
            </div>
          </div>
        </div>

      </div>
    </div>
  );
}

export default TopNav;
