import React, { useState } from "react";
import "./styles.scss";
import { Link, useNavigate } from "react-router-dom";
import Popup from "../../Components/PopUp";
import Spinner from "../../Components/Spinner";
import StyledInput from "../../Components/StyledInput";
import { url } from "../../utils/utils";
import PrimaryButton from "../../Components/PrimaryButton";
import Sidebar from "../../Components/Auth/SignSideBar";

const SignIn = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const [formErrors, setFormErrors] = useState({
    email: "",
    password: "",
  });

  const [showPopup, setShowSuccess] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const errors = {};

    if (formData.password.trim() === "") {
      errors.password = "Password is required";
    }

    if (formData.email.trim() === "" || !/\S+@\S+\.\S+/.test(formData.email)) {
      errors.email = "Email is not valid";
    }

    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
    } else {
      setShowSpinner(true);
      setFormErrors("");

      var myHeaders = new Headers();
      myHeaders.append(
        "api-auth-key",
        "mobile_tech_solution_1234567890987654321"
      );
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        email: formData.email,
        password: formData.password,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const errors = {};

      fetch(`${url}/api/users/login`, requestOptions)
        .then((response) => response.json())
        .then((result) => {
          if (
            result.message === "Email does not exist. Please create an account."
          ) {
            errors.email =
              "This account does not exist. Kindly create an account";
            setFormErrors(errors);
            setShowSpinner(false);
          } else if (result.message === "Auth failed") {
            errors.password =
              "Email or password is incorrect. Check and try again";
            setFormErrors(errors);
            setShowSpinner(false);
          } else if (result.message === "Auth Successful") {
            // console.log("result", result);
            setShowSpinner(false);

            localStorage.setItem("userData", JSON.stringify(result.data));
            localStorage.setItem("token", result.token);
            const currentTime = new Date();
            localStorage.setItem("authTimein", currentTime);
            // If no errors, show success message

            setShowSuccess(true);

            // Hide the success message after 2 seconds
            setTimeout(() => {
              setShowSuccess(false);

              // Navigate to the main dashboard upon successful registration
              navigate("/dashboard");

              setFormData({
                // Clear the form data and errors after submission
                email: "",
                password: "",
              });
              setFormErrors({
                email: "",
                password: "",
              });
            }, 2000);
          } else {
            errors.password = "Authentication Error";
            setFormErrors(errors);
            setShowSpinner(false);
            // console.log({result})
          }
        })
        .catch((error) => {
          // console.log(error.message);

          if(error?.message === 'Failed to fetch'){
            errors.password = "Network Error";
          }

          setShowSpinner(false);
          
        });
    } 
  };

  return (
    <div className="auth-page">
      <Sidebar />
      <div className="form-container">
      <form onSubmit={handleSubmit} className="form" autoComplete="true">
          <div className="head">
            <h2>Login</h2>
            <p>Login to your account</p>
          </div>

          <StyledInput
            label={'Email Address'}
            handleChange={handleInputChange}
            value={formData.email}
            type='email'
            formError={formErrors.email}
            name="email"
            id="email"
            required
          />

          <StyledInput
            label={'Password'}
            handleChange={handleInputChange}
            value={formData.password}
            formError={formErrors.password}
            name="password"
            id="password"
            password
            required
          />


          {/* Select component for  country */}
          {/* <Select
            className="select-country"
            value={formData.country}
            onChange={handleCountryChange}
            options={[
              { value: "NG", label: "Nigeria" },
              { value: "USA", label: "United States" },
              { value: "Gh", label: "Ghana" },
              { value: "SA", label: "South Africa" },
              { value: "CAN", label: "Canada" },
            ]}
            placeholder="Select your country"
          /> */}

          {/* {formErrors.country && (
            <span className="error">{formErrors.country}</span>
          )} */}

          <div className="info">
            <p>Password should be atleast 8 Characters long, 1 cap letter and number included.</p>
          </div>

          {/* <div className="checkbox-group">
            <input type="checkbox" id="termsCheckbox" name="termsCheckbox" onChange={(ch) => setCheckbox(ch.target.checked)} />
            <label htmlFor="termsCheckbox">
              I agree to the <Link>terms of service</Link> and <Link>privacy policy</Link>
            </label>
          </div> */}

          <p className="forgot-password">
            <Link to="/ForgetPassword">Forgot password?</Link>
          </p>

          <PrimaryButton
            type="submit"
            text={'Login'}
            color={'var(--primary-color)'}
          />

          <p className="already-have-account">
            Don't have an account? <Link to="/SignUp">Register</Link>
          </p>
        </form>

        {showSpinner && <Spinner />}

        {/* Success popup */}
        {showPopup && (
          <Popup
            message="Authentication successful!"
            onClose={() => setShowSuccess(false)}
            show={showPopup}
            title={"Authentication"}
          />
        )}
      </div>
    </div>
  );
};

export default SignIn;
