import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./style.scss";
import { url } from "../../../utils/utils";
import arrow from "../../../assets/arrow-down.svg";
import phone from "../../../assets/phone-mockup.jpg";

export default function SEND() {
  const [userData, setUserData] = useState({});
  const [token, setToken] = useState({});
  const [selectedOption, setSelectedOption] = useState("true");
  const [selectedSmsType, setSelectedSmsType] = useState(1);
  const [selectedRouteType, setSelectedRouteType] = useState("MKT");
  const [formData, setFormData] = useState({
    sender: "",
    recipients: "",
    msg: "",
    type: selectedSmsType,
    route: selectedRouteType,
  });
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const msgIdValues = [];
  const [isSidebarOpen, setSidebarOpen] = useState(false);
  const [isChecked1, setIsChecked1] = useState(true);
  const [isChecked2, setIsChecked2] = useState(false);
  const [contactGrp, setContactGrp] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState("");

  const handleGroupChange = async (event) => {
    const groupId = event.target.value;
    setSelectedGroup(groupId);

    // console.log(groupId)
    try {
      const response = await fetch(`${url}/api/contacts/getgroupnumbers?groupId=${groupId}`);
      const data = await response.json();
      const numbers = data.numbers.map(number => number.number).join(',');

      setFormData(prevFormData => ({
        ...prevFormData,
        recipients: numbers
      }));
    } catch (error) {
      console.error('Error fetching group numbers:', error);
    }
  };

  const handleCheckboxChange = (checkboxNumber) => {
    if (checkboxNumber === 1) {
      setIsChecked1(true);
      setIsChecked2(false);
    } else {
      setIsChecked1(false);
      setIsChecked2(true);
    }
  };
  const handleToggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  useEffect(()=>{
    const usrDT = localStorage.getItem("userData");
    const tk = localStorage.getItem("token");
    const retrievedData = JSON.parse(usrDT);
    setUserData(retrievedData);

    setToken(tk);

    // console.log(retrievedData.uid)

    if(retrievedData.uid){
      fetchContactGroups(retrievedData.uid)
    }

  },[userData.uid])

  // useEffect(() => {
    
  //   setFormData({
  //     ...formData,
  //     type: selectedSmsType,
  //     route: selectedRouteType,
  //   });
  // }, [selectedSmsType, selectedRouteType, formData]);

  const handleSmsChange = (event) => {
    setSelectedSmsType(Number(event.target.value));
  };

  // useEffect(()=>{
  //   console.log('uid',userData.uid)
  //   if(userData.uid){
  //     fetchContactGroups(userData.uid)
  //   }
  // },[userData.uid])


  const fetchContactGroups = async (uid) => {
    // console.log(uid)
    try {
      const response = await fetch(`${url}/api/contacts/contactgroup?uid=${uid}`, {
        method: 'GET',

      });
      const result = await response.json();
      // console.log(result)

      if (response.ok) {
        // console.log('Contact Groups:', result);


        setContactGrp(result.data)
        // console.log(result)
      } else {
        // if (result.status === 'bad') {
        //     navigate("/SignIn");
        //     return;
        // }
        console.error('Error:', result.message || result.error);
      }
    } catch (error) {
      console.error('Fetch error:', error);
    }
  };

  const handleSubmit = (e) => {
    setLoading(true);
    e.preventDefault();
    const timestamp = Date.now();

    const date = new Date(timestamp);

    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();

    const id = `${year}${month}${day}${hours}${minutes}${seconds}`;

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${token}`);

    var raw = JSON.stringify(formData);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${url}/api/sms/web/send?uid=${userData.uid}`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        // console.log({ data })
        if (data.error === "err:573") {
          setError(data.message);
          setLoading(false);
        } else if (data.error === "err:bal12") {
          setError(data.message);
          setLoading(false);
        } else if (data.message === "Auth failed/Token Expired") {
          navigate("/SignIn");
          setLoading(false);
        } else {
          const setResults = async (prevArray) => {
            const updatedResults = [...(prevArray || []), data];
            const msgIds = updatedResults?.map((obj) =>
              obj.data.map((innerObj) => innerObj.msg_id)
            );

            msgIds.forEach((innerArray) => {
              innerArray.forEach((value) => {
                msgIdValues.push(value);
              });
            });

            const uniqueMsgIdValues = [...new Set(msgIdValues)];

            const storeDT = {
              ids: uniqueMsgIdValues,
              route: selectedRouteType,
              type: selectedSmsType,
              senderId: formData.sender
            }

            localStorage.setItem(id, JSON.stringify(storeDT));

            return updatedResults;
          };

          setResults().then(() => navigate(`/sms/sent/${id}`)).finally(() => setLoading(false))

        }
      })
      .catch((error) => {
        setLoading(false);
        console.log({ error });
      });
  };

  const handleRouteChange = (event) => {
    setSelectedRouteType(event.target.value)

    setFormData(prevFormData => ({
      ...prevFormData,
      route: event.target.value
    }));
  };

  return (
    <section className="sms-app">
      <main className="form-area">
        <div className="form-body">
          <div className="form-header">
            <h2>Send Bulk SMS</h2>
            <div>
              <p>Dashboard</p>
              <img src={arrow} alt="" />
              <p>SMS</p>
              <img src={arrow} alt="" />
              <span>Send SMS</span>
            </div>
          </div>
          <div className="image-holder">
            <div className="form-container">
              {error ? <p className="error-message">{error}</p> : null}
              <form className="app-form" onSubmit={handleSubmit}>
                <div>
                  <div className="message-sender">
                    <label>Sender ID</label>
                    <input
                      type="text"
                      placeholder="Sender ID"
                      name="sender"
                      value={formData.sender}
                      onChange={handleChange}
                    />
                  </div>

                  <div className="message-sender">
                    <label>Select Contact Group</label>
                    <select
                      placeholder="Select Contact Group"
                      className="select-option"
                      value={selectedGroup}
                      onChange={handleGroupChange}
                      disabled={!isChecked2}
                    >
                      <option value="">Select Contact Group</option>
                      {contactGrp.map((group) => (
                        <option key={group._id} value={group._id}>
                          {group.groupName}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                <div className="message-sender">
                  <p>Recipient</p>
                  <div className="radio-options">
                    <div>
                      <input
                        type="checkbox"
                        name="entry"
                        id="unique"
                        checked={isChecked1}
                        onChange={() => handleCheckboxChange(1)}
                      />
                      <label>Manual Entry</label>
                    </div>
                    <div>
                      <input
                        type="checkbox"
                        name="entry"
                        id="unique"
                        checked={isChecked2}
                        onChange={() => handleCheckboxChange(2)}
                      />
                      <label>Select Contact group</label>
                    </div>
                  </div>
                </div>

                <div>
                  <div className="message-sender">
                    <label>Manual Contact Entry</label>
                    <textarea
                      cols={10}
                      rows={10}
                      name="recipients"
                      disabled={isChecked2}
                      placeholder="08012345678,08090123456,070789012345,07067890123456"
                      value={formData.recipients}
                      onChange={handleChange}
                    ></textarea>
                    <p className="helper-text">
                      Fill contact info correctly and if there’s none kindly
                      fill in NIL
                    </p>
                  </div>

                  <div className="message-sender">
                    <label>Message</label>
                    <textarea
                      cols={10}
                      rows={10}
                      name="msg"
                      placeholder="Message"
                      value={formData.msg}
                      onChange={handleChange}
                    ></textarea>
                  </div>
                </div>
              </form>

              <div className="select_option">
                <div className="sms_type_input">
                  <p>SMS Type/Class</p>
                  <div className="radio_option">
                    <div>
                      <input
                        type="checkbox"
                        name="type"
                        id="unique"
                        value="1"
                        checked={selectedSmsType === 1}
                        onChange={handleSmsChange}
                      />
                      <label>Normal</label>
                    </div>
                    <div>
                      <input
                        type="checkbox"
                        name="type"
                        id="unique"
                        value="0"
                        checked={selectedSmsType === 0}
                        onChange={handleSmsChange}
                      />
                      <label>Flash</label>
                    </div>
                  </div>
                </div>

                <div className="sms_type_input">
                  <p>SMS Route</p>
                  <div className="radio_option">
                    <div>
                      <input
                        type="checkbox"
                        name="route"
                        id="unique"
                        value="MKT"
                        checked={selectedRouteType === "MKT"}
                        onChange={handleRouteChange}
                      />
                      <label>MKT</label>
                    </div>
                    <div>
                      <input
                        type="checkbox"
                        name="route"
                        id="unique"
                        checked={selectedRouteType === "TRX"}
                        value="TRX"
                        onChange={handleRouteChange}
                      />
                      <label>TRX</label>
                    </div>
                  </div>
                </div>
              </div>

              <div className="button">
                <button type="submit" onClick={handleSubmit} disabled={loading}>
                  {loading ? "Sending..." : "Send Message"}
                </button>
              </div>
            </div>
            <div className="phone-image">
              <img src={phone} alt="" />
              <div className="image-text">
                <p>{formData.msg}</p>
              </div>
            </div>
          </div>
        </div>
      </main>
    </section>
  );
}
